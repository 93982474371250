import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LangRouter from "./components/lang-router/LangRouter";

// import RequireAuth from "./components/RequireAuth/RequireAuth";

// Pages
const LandingPage = React.lazy(() =>
  import("./pages/landing-page/LandingPage")
);
const AuthPage = React.lazy(() => import("./pages/auth-page/AuthPage"));
const NoMatchPage = React.lazy(() => import("./pages/no-match/NoMatch"));
const ProfilePage = React.lazy(() =>
  import("./pages/profile-page/ProfilePage")
);

const Contracts = React.lazy(() => import("./pages/profile-page/contract"));
const LeasingInfo = React.lazy(() =>
  import("./pages/profile-page/contract/leasing")
);
const PropertyInfo = React.lazy(() =>
  import("./pages/profile-page/contract/properties")
);
const Additionals = React.lazy(() =>
  import("./pages/profile-page/contract/additionals")
);
const Payments = React.lazy(() =>
  import("./pages/profile-page/contract/payments")
);
const PdfPage = React.lazy(() => import("./pages/profile-page/contract/pdf"));

const ForgetPassword = React.lazy(() =>
  import("./pages/auth-page/forget-password")
);
const ResetPassword = React.lazy(() =>
  import("./pages/auth-page/reset-password")
);
const LandlordInfo = React.lazy(() =>
  import("./pages/profile-page/contract/landlord")
);
const TenantInfo = React.lazy(() =>
  import("./pages/profile-page/contract/tenant")
);

// Components
const LoginForm = React.lazy(() => import("./pages/auth-page/login-form"));
const RegisterForm = React.lazy(() =>
  import("./pages/auth-page/register-form")
);
const LandlordAccount = React.lazy(() =>
  import("./pages/profile-page/landlord-account/index")
);
const TenantAccount = React.lazy(() =>
  import("./pages/profile-page/tenant-account/index")
);

const ResidentialAccount = React.lazy(() =>
  import("./pages/profile-page/residential-account/index")
);

const CommercialAccount = React.lazy(() =>
  import("./pages/profile-page/commercial-account/index")
);

const MultiAccounts = React.lazy(() =>
  import("./pages/profile-page/multi-accounts/index")
);

const UserAccount = React.lazy(() =>
  import("./pages/profile-page/user-account")
);

const ArtificialIntelligence = React.lazy(() =>
  import("./pages/profile-page/ai")
);

const LegalTerms = React.lazy(() => import("./pages/profile-page/legal-terms"));

const FirebaseUser = React.lazy(() =>
  import("./pages/auth-page/firebase-user")
);

const VerifyContract = React.lazy(() =>
  import("./pages/auth-page/verify-contract")
);

// get the current language
const currentLanguageCode = localStorage.getItem("i18nextLng") || "ar";

function Router() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/${currentLanguageCode}/home`} />}
        />
        <Route path=":lang" element={<LangRouter />}>
          <Route path="home" element={<LandingPage />} />
          <Route path="fb-user" element={<FirebaseUser />} />
          <Route path="verify-contract" element={<VerifyContract />} />
          <Route path="auth" element={<AuthPage />}>
            <Route
              index
              element={<Navigate to={`/${currentLanguageCode}/auth/login`} />}
            />
            <Route path="login" element={<LoginForm />} />
            <Route path="register" element={<RegisterForm />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>

          <Route path="profile" element={<ProfilePage />}>
            <Route
              index
              element={
                <Navigate to={`/${currentLanguageCode}/profile/contract`} />
              }
            />
            <Route path="landlord" element={<LandlordAccount />} />
            <Route path="tenant" element={<TenantAccount />} />
            <Route path="residential" element={<ResidentialAccount />} />
            <Route path="commercial" element={<CommercialAccount />} />
            <Route path="multi-account" element={<MultiAccounts />} />
            <Route path="legal-terms" element={<LegalTerms />} />

            <Route path="contract" element={<Contracts />} />
            {/* <Route path="contract/individual" element={<IndividualInfo />} /> */}
            <Route path="contract/landlord" element={<LandlordInfo />} />
            <Route path="contract/tenant" element={<TenantInfo />} />

            <Route path="contract/leasing" element={<LeasingInfo />} />
            <Route path="contract/property" element={<PropertyInfo />} />
            <Route path="contract/additionals" element={<Additionals />} />
            <Route path="contract/payments" element={<Payments />} />
            <Route path="contract/pdf" element={<PdfPage />} />
            <Route path="user" element={<UserAccount />} />
            <Route path="ai" element={<ArtificialIntelligence />} />

            <Route path="*" element={<NoMatchPage />} />
          </Route>

          <Route path="*" element={<NoMatchPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default Router;
