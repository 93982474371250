import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user-form",
  initialState: {
    user_id: "",
    is_corporate: "false",

    display_name: "",
    email: "",

    user_type: "tenant",
    first_name: "",
    second_name: "",
    third_name: "",
    family_name: "",
    nationality: "Jordan",
    is_residential: "false",
    id_number: "",
    passport_number: "",
    residential_number: "",
    non_jordanian_serial_number: "",
    residential_serial_number: "",
    status: "",

    phone_number: "",
    address: "",

    corporate_name: "",
    corporate_sign_name: "",
    commercial_registration_number: "",

    is_edit: "false",

    id_image_url: "",
    passport_image_url: "",
    non_jordanian_serial_image_url: "",
    residential_serial_image_url: "",
    residential_image_url: "",
    commercial_registration_image_url: {
      url: "",
      file_type: "",
    },
    profile_image_url: {
      url: "",
      file_type: "",
    },
    family_book_url: "",

    id_image: "",
    passport_image: "",
    residential_image: "",
    non_jordanian_serial_image: "",
    residential_serial_image: "",
    commercial_registration_image: "",
    profile_image: "",
    family_book: "",
    brand_color: "#e73137",
    brand_font_color: "#ffffff",
    company_logo: "",
    company_logo_url: "",
  },
  reducers: {
    changeProfilePicture: (state, action) => {
      state.profile_image_url.url = action.payload?.value || "";
    },
    // actions => action handlers
    setInputValue: (state, action) => {
      const skipEdit = [
        "id_image",
        "passport_image",
        "residential_image",
        "non_jordanian_serial_image",
        "residential_serial_image",
        "commercial_registration_image",
        "profile_image",
        "family_book",
        "company_logo",
      ];
      if (skipEdit.includes(action.payload?.key)) {
      } else {
        state.is_edit = "true";
      }
      state[action.payload?.key] = action.payload?.value;
    },
    resetFileInputs: (state, action) => {
      state.id_image = "";
      state.passport_image = "";
      state.residential_image = "";
      state.non_jordanian_serial_image = "";
      state.residential_serial_image = "";
      state.commercial_registration_image = "";
      state.profile_image = "";
      state.family_book = "";
      state.company_logo = "";
    },
    resetReqFileInputs: (state, action) => {
      state.id_image = "";
      state.passport_image = "";
      state.residential_image = "";
      state.non_jordanian_serial_image = "";
      state.residential_serial_image = "";
      state.commercial_registration_image = "";
    },
    resetInputsValue: (state, action) => {
      state.first_name = "";
      state.second_name = "";
      state.third_name = "";
      state.family_name = "";
      state.email = "";
      state.phone_number = "";
      state.address = "";
      state.nationality = "Jordan";
      state.is_residential = "false";
      state.is_corporate = "false";
      state.id_number = "";
      state.id_image = "";
      state.passport_number = "";
      state.passport_image = "";
      state.residential_number = "";
      state.residential_image = "";
      state.non_jordanian_serial_number = "";
      state.non_jordanian_serial_image = "";
      state.residential_serial_number = "";
      state.residential_serial_image = "";
      state.is_inheritance = "false";
      state.is_secondary_tenant = "false";
      state.is_edit = "false";

      state.id_image_url = "";
      state.passport_image_url = "";
      state.non_jordanian_serial_image_url = "";
      state.residential_serial_image_url = "";
      state.residential_image_url = "";
      state.commercial_registration_image_url = "";
      state.profile_image_url = "";
      state.family_book_url = "";
      state.company_logo_url = "";
      state.brand_color = "#e73137";
      state.brand_font_color = "#ffffff";
    },
    setUserDataToEdit: (state, action) => {
      state.user_id = action.payload?.user_id || "";
      state.current_nationality_type = action.payload?.nationality_type || "";
      state.display_name = action.payload?.display_name || "";
      state.email = action.payload?.email || "";

      state.is_corporate = action.payload?.is_corporate ? "true" : "false";
      state.user_type = action.payload?.user_type || "tenant";

      state.first_name = action.payload?.first_name || "";
      state.second_name = action.payload?.father_name || "";
      state.third_name = action.payload?.grand_father_name || "";
      state.family_name = action.payload?.family_name || "";
      state.nationality = action.payload?.nationality || "Jordan";
      state.is_residential =
        action.payload?.nationality_type === "jo_nationality" ||
        action.payload?.nationality_type === "none_jo_nationality" ||
        action.payload?.nationality_type === null
          ? "false"
          : "true";

      state.non_jordanian_serial_number = action.payload?.serial_number || "";
      state.residential_serial_number = action.payload?.serial_number || "";

      state.id_number =
        action.payload?.nationality_type === "jo_nationality"
          ? action.payload?.government_number
          : "";
      state.passport_number =
        action.payload?.nationality_type === "none_jo_nationality"
          ? action.payload?.government_number
          : "";
      state.residential_number =
        action.payload?.nationality_type === "jo_residential"
          ? action.payload?.government_number
          : "";

      state.phone_number =
        action.payload?.country_code + action.payload?.phone_number || "";
      state.address = action.payload?.address || "";
      state.status = action.payload?.status || "";

      state.corporate_name = action.payload?.corporate_name || "";
      state.corporate_sign_name = action.payload?.corporate_sign_name || "";
      state.commercial_registration_number =
        action.payload?.government_number || "";

      state.non_jordanian_serial_image = "";
      state.id_image = "";
      state.passport_image = "";
      state.residential_image = "";
      state.residential_serial_image = "";
      state.brand_color = action.payload?.brand_color || "#e73137";
      state.brand_font_color = action.payload?.brand_font_color || "#ffffff";
      state.company_logo = "";

      // handle images URLs
      state.id_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "nationality_id"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "nationality_id"
          )[0]?.file_type || "",
        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "nationality_id"
          )[0]?.file_id || "",
      };

      state.passport_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "passport"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "passport"
          )[0]?.file_type || "",
        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "passport"
          )[0]?.file_id || "",
      };

      state.non_jordanian_serial_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_type || "",
        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_id || "",
      };

      state.residential_serial_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_type || "",
        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_id || "",
      };

      state.residential_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "residential"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "residential"
          )[0]?.file_type || "",
        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "residential"
          )[0]?.file_id || "",
      };

      state.commercial_registration_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_type || "",
        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_id || "",
      };

      state.profile_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "profile_picture"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "profile_picture"
          )[0]?.file_type || "",

        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "profile_picture"
          )[0]?.file_id || "",
      };

      state.family_book_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "family_book"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "family_book"
          )[0]?.file_type || "",

        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "family_book"
          )[0]?.file_id || "",
      };

      state.company_logo_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "company_logo"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "company_logo"
          )[0]?.file_type || "",

        file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "company_logo"
          )[0]?.file_id || "",
      };
    },
  },
});

export const {
  setInputValue,
  resetInputsValue,
  resetFileInputs,
  setUserDataToEdit,
  resetReqFileInputs,
  changeProfilePicture
} = slice.actions;
export default slice.reducer;
