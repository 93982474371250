import { createSlice } from '@reduxjs/toolkit';

const imageSlice = createSlice({
  name: 'image',
  initialState: {
    file: null, // Initial state is null indicating no image is selected
  },
  reducers: {
    setImageFile: (state, action) => {
      state.file = action.payload; // Set the image file in the state
    },
  },
});

export const { setImageFile } = imageSlice.actions;
export default imageSlice.reducer;