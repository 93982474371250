import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "additionals",
  initialState: {
    leasingInfo: {},
    allowed_smoking: "true",
    smoking_term: "",
    pet_allowed: "true",
    pet_term: "",
    is_parking: "false",
    parking_term: "",
    is_garden: "false",
    garden_term: "",
    is_water: "",
    is_electricity: "",
    is_condition: "",
    is_solar: "",
    rent_include_term: "",
    parking_count: "1",
    is_built_in_kitchen: "true",
    is_built_in_condition: "false",
    condition_count: "1",
  },
  reducers: {
    // actions => action handlers
    setInputValue: (state, action) => {
      state[action.payload?.key] = action.payload?.value;
    },
    resetFileInputs: (state, action) => {
      state.commercial_registration_image = "";
    },
    resetInputsValue: (state, action) => {
      state.leasingInfo = {};
      state.allowed_smoking = "true";
      state.smoking_term = "";
      state.pet_allowed = "true";
      state.pet_term = "";
      state.is_parking = "false";
      state.parking_term = "";
      state.is_garden = "false";
      state.garden_term = "";
      state.is_water = "";
      state.is_electricity = "";
      state.is_condition = "";
      state.is_solar = "";
      state.rent_include_term = "";
      state.parking_count = "1";
      state.is_built_in_kitchen = "true";
      state.is_built_in_condition = "false";
      state.condition_count = "1";
    },
    setPrevData: (state, action) => {
      state.leasingInfo = action.payload?.data?.leasingInfo;
      state.allowed_smoking = action.payload?.data?.allowed_smoking;
      state.smoking_term = action.payload?.data?.smoking_term;
      state.pet_allowed = action.payload?.data?.pet_allowed;
      state.pet_term = action.payload?.data?.pet_term;
      state.is_parking = action.payload?.data?.is_parking;
      state.parking_term = action.payload?.data?.parking_term;
      state.is_garden = action.payload?.data?.is_garden;
      state.garden_term = action.payload?.data?.garden_term;
      state.is_water = action.payload?.data?.is_water;
      state.is_electricity = action.payload?.data?.is_electricity;
      state.is_condition = action.payload?.data?.is_condition;
      state.is_solar = action.payload?.data?.is_solar;
      state.rent_include_term = action.payload?.data?.rent_include_term;
      state.parking_count = action.payload?.data?.parking_count;
      state.is_built_in_kitchen = action.payload?.data?.is_built_in_kitchen;
      state.is_built_in_condition = action.payload?.data?.is_built_in_condition;
      state.condition_count = action.payload?.data?.condition_count;
    },
  },
});

export const {
  setInputValue,
  resetInputsValue,
  setCompDataToEdit,
  resetFileInputs,
  setPrevData,
} = slice.actions;
export default slice.reducer;
