import axios from "axios";

import { handleError } from "./error";
import {
  getLocalStorage,
  toastCreate,
  removeLocalStorage,
  removeSessionStorage,
} from "../utils/global";

const privateAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  credentials: "include",
});

privateAxios.interceptors.request.use(
  (config) => {
    const aqdeeStorage = getLocalStorage();
    const token = aqdeeStorage.user_info["token"];

    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

privateAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (
      err?.response?.data?.error?.message === "Token has expired" ||
      err?.response?.data?.message?.code === "auth/id-token-expired" ||
      err?.response?.data?.message?.message ===
        "Firebase ID token has expired. Get a fresh ID token from your client app and try again (auth/id-token-expired). See https://firebase.google.com/docs/auth/admin/verify-id-tokens for details on how to retrieve an ID token." ||
      err?.response?.status === 401
    ) {
      removeLocalStorage();
      removeSessionStorage();
      window.location.reload(false);
    }

    toastCreate("error", handleError(err));
    return Promise.reject(err);
  }
);

const method = {
  get: privateAxios.get,
  post: privateAxios.post,
  put: privateAxios.put,
  delete: privateAxios.delete,
  patch: privateAxios.patch,
  CancelToken: privateAxios.CancelToken,
};

export default method;
