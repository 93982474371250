import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "commercial-form",
  initialState: {
    commercial_name: "",
    corporate_profile_number: "",

    corporate_profile_image: "",
    corporate_profile_image_url: "",
    permission_works_image: "",
    permission_works_image_url: "",
    land_plan_image: "",
    land_plan_image_url: "",
    organizational_chart_image: "",
    organizational_chart_image_url: "",

    state_id: "",
    directorate_id: "",
    village_id: "",
    sector_id: "",
    hod_id: "",
    piece_number: "",
    registration_number: "",
    street_name: "",
    building_number: "",
    closest_place: "",

    registration_document_image: "",
    registration_document_image_url: "",

    property_building_year: "",
    total_count_of_apartments: "",
    value_of_roof_tax: "",

    is_commercial_offices: "true",
    is_edit: "false",
    is_furnished_edit: "true",
    apartment_images_url: [],
  },
  reducers: {
    // actions => action handlers
    setCommercialInputValue: (state, action) => {
      // if vlaue changed, call update
      if (
        action.payload?.key === "corporate_profile_image" ||
        action.payload?.key === "permission_works_image" ||
        action.payload?.key === "organizational_chart_image" ||
        action.payload?.key === "land_plan_image" ||
        action.payload?.key === "registration_document_image" ||
        action.payload?.key === "apartment_images_url"
      ) {
      } else {
        state.is_edit = "true";
      }

      state[action.payload?.key] = action.payload?.value;
    },
    resetCommercialInputsValues: (state, action) => {
      state.commercial_name = "";
      state.corporate_profile_number = "";
      state.corporate_profile_image = "";
      state.corporate_profile_image_url = "";
      state.permission_works_image = "";
      state.permission_works_image_url = "";
      state.land_plan_image = "";
      state.land_plan_image_url = "";
      state.organizational_chart_image = "";
      state.organizational_chart_image_url = "";

      state.state_id = "";
      state.directorate_id = "";
      state.village_id = "";
      state.sector_id = "";
      state.hod_id = "";
      state.piece_number = "";
      state.registration_number = "";
      state.street_name = "";
      state.building_number = "";
      state.closest_place = "";
      state.registration_document_image = "";
      state.registration_document_image_url = "";

      state.property_building_year = "";
      state.total_count_of_apartments = "";
      state.value_of_roof_tax = "";

      state.is_commercial_offices = "true";
      state.is_edit = "false";
      state.is_furnished_edit = "false";
      state.apartment_images_url = [];
    },
    setCommercialDataToEdit: (state, action) => {
      state.commercial_profile_id = action.payload?.commercial_profile_id || "";

      state.commercial_name = action.payload?.name || "tt";
      state.corporate_profile_number =
        action.payload?.corporate_profile_number || "";

      state.state_id = String(action.payload?.state?.state_id) || "";
      state.directorate_id =
        String(action.payload?.directorate?.directorate_id) || "";
      state.village_id = String(action.payload?.village?.village_id) || "";
      state.sector_id = String(action.payload?.sector?.sector_id) || "";
      state.hod_id = String(action.payload?.hod?.hod_id) || "";
      state.piece_number = action.payload?.piece_number || "";
      state.registration_number = action.payload?.registration_number || "";
      state.street_name = action.payload?.street_name || "";
      state.building_number = action.payload?.building_number || "";
      state.closest_place = action.payload?.closest_place || "";

      state.property_building_year =
        action.payload?.property_building_year || "";
      state.total_count_of_apartments =
        action.payload?.total_count_of_apartments || "";
      state.value_of_roof_tax = action.payload?.value_of_roof_tax || "";

      state.is_commercial_offices = action.payload?.commercial_offices
        ? "true"
        : " false";

      // state.residential_state = action.payload?.residential_state || "";
      // state.current_residential_state = action.payload?.residential_state || "";

      // state.item_name = action.payload?.furnished_items ? action.payload?.furnished_items[0]?.item_name : "";
      // state.item_state = action.payload?.furnished_items ? action.payload?.furnished_items[0]?.item_state : "excellent";
      // state.item_description =
      // action.payload?.furnished_items ? action.payload?.furnished_items[0]?.item_description : "";
      // state.furnished_item_id =
      // action.payload?.furnished_items ? action.payload?.furnished_items[0]?.furnished_item_id : "";
      // state.is_video = ( (action.payload?.asset_file || []).filter(
      //   (asset) => asset.post_specification === "furnished_video"
      // )).length !==0 ? "true" : "false";

      state.registration_document_image = "";
      state.corporate_profile_image = "";
      state.permission_works_image = "";
      state.land_plan_image = "";
      state.organizational_chart_image = "";
      state.apartment_images_url = [];

      state.corporate_profile_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_id || "",
      };

      state.permission_works_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "permission_works"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "permission_works"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "permission_works"
          )[0]?.file_id || "",
      };

      state.land_plan_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "land_plan"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "land_plan"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "land_plan"
          )[0]?.file_id || "",
      };

      state.organizational_chart_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "organizational_chart"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "organizational_chart"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "organizational_chart"
          )[0]?.file_id || "",
      };

      state.registration_document_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "registration_document"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "registration_document"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "registration_document"
          )[0]?.file_id || "",
      };

      state.apartment_images_url =
        (action.payload?.asset_file || []).filter(
          (asset) => asset.post_specification === "apartment_image"
        ) || [];
    },
  },
});

export const {
  setCommercialInputValue,
  resetCommercialInputsValues,
  setCommercialDataToEdit,
} = slice.actions;
export default slice.reducer;
