import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const STORAGE = "aqdee";
const STORAGE_INIT = '{"user_info": {}}';

export const getCurrentDomain = () => {
  const domain = window.location.hostname;
  if (domain === "localhost") {
    return `${window.location.protocol}//localhost:3000`;
  }
  return `${window.location.protocol}//${domain}`;
};

export function toastCreate(type, message) {
  if (type === "error") {
    return toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  if (type === "success") {
    return toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

export const supportedLanguage = ["ar", "en"];

export const currentLanguageCode = localStorage.getItem("i18nextLng");

export function isTranslatedPath(url) {
  // Use a regular expression to match "/en" at the beginning of the path
  // const regex = new RegExp(`^\\/${currentLanguageCode}\\b`);
  // return regex.test(url);
  const regex = /^\/\w{2}\b/;
  return regex.test(url);
}

export const userLogout = (dispatch, dispatchFucntion, navigate) => {
  dispatch(dispatchFucntion);
  removeLocalStorage();
  removeSessionStorage();
  navigate("/auth/login", { replace: true });
};

export const setLocalStorage = (obj) => {
  localStorage.setItem(STORAGE, JSON.stringify(obj));
};

export const getLocalStorage = () => {
  let obj = localStorage.getItem(STORAGE);

  if (!obj) {
    obj = STORAGE_INIT;
  }

  return JSON.parse(obj);
};

export const removeLocalStorage = () => {
  localStorage.removeItem(STORAGE);
};

export const chunkArr = (arr, chunkSize) => {
  const chunked = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunked.push(arr.slice(i, i + chunkSize));
  }
  return chunked;
};

export const generateRandomId = () => {
  const min = 1000; // Minimum 4-digit number
  const max = 9999; // Maximum 4-digit number
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const country_list_en = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua &amp; Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia &amp; Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const country_list_ar = [
  "المملكة العربية السعودية",
  "إثيوبيا",
  "أذربيجان",
  "أرمينيا",
  "أروبا",
  "إريتريا",
  "أسبانيا",
  "أستراليا",
  "إستونيا",
  "أفغانستان",
  "إقليم المحيط الهندي البريطاني",
  "إكوادور",
  "الأرجنتين",
  "الأردن",
  "الإمارات العربية المتحدة",
  "ألبانيا",
  "البرازيل",
  "البرتغال",
  "البوسنة والهرسك",
  "الجابون",
  "الجزائر",
  "الدانمارك",
  "الرأس الأخضر",
  "السلفادور",
  "السنغال",
  "السودان",
  "السويد",
  "الصومال",
  "الصين",
  "العراق",
  "الفلبين",
  "الكاميرون",
  "الكونغو",
  "الكونغو (جمهورية الكونغو الديمقراطية)",
  "الكويت",
  "ألمانيا",
  "المجر",
  "المغرب",
  "المكسيك",
  "المملكة المتحدة",
  "المناطق الفرنسية الجنوبية ومناطق انتراكتيكا",
  "النرويج",
  "النمسا",
  "النيجر",
  "الهند",
  "الولايات المتحدة",
  "اليابان",
  "اليمن",
  "اليونان",
  "أنتاركتيكا",
  "أنتيغوا وبربودا",
  "أندورا",
  "إندونيسيا",
  "أنغولا",
  "أنغويلا",
  "أوروجواي",
  "أوزبكستان",
  "أوغندا",
  "أوكرانيا",
  "إيران",
  "أيرلندا",
  "أيسلندا",
  "إيطاليا",
  "بابوا-غينيا الجديدة",
  "باراجواي",
  "باربادوس",
  "باكستان",
  "بالاو",
  "برمودا",
  "بروناي",
  "بلجيكا",
  "بلغاريا",
  "بنجلاديش",
  "بنما",
  "بنين",
  "بوتان",
  "بوتسوانا",
  "بورتو ريكو",
  "بوركينا فاسو",
  "بوروندي",
  "بولندا",
  "بوليفيا",
  "بولينزيا الفرنسية",
  "بيرو",
  "بيلاروس",
  "بيليز",
  "تايلاند",
  "تايوان",
  "تركمانستان",
  "تركيا",
  "ترينيداد وتوباجو",
  "تشاد",
  "تشيلي",
  "تنزانيا",
  "توجو",
  "توفالو",
  "توكيلاو",
  "تونجا",
  "تونس",
  "تيمور الشرقية (تيمور الشرقية)",
  "جامايكا",
  "جامبيا",
  "جبل طارق",
  "جرينلاند",
  "جزر الأنتيل الهولندية",
  "جزر البتكارين",
  "جزر البهاما",
  "جزر العذراء البريطانية",
  "جزر العذراء، الولايات المتحدة",
  "جزر القمر",
  "جزر الكوكوس (كيلين)",
  "جزر المالديف",
  "جزر تركس وكايكوس",
  "جزر ساموا الأمريكية",
  "جزر سولومون",
  "جزر فايرو",
  "جزر فرعية تابعة للولايات المتحدة",
  "جزر فوكلاند (أيزلاس مالفيناس)",
  "جزر فيجي",
  "جزر كايمان",
  "جزر كوك",
  "جزر مارشال",
  "جزر ماريانا الشمالية",
  "جزيرة الكريسماس",
  "جزيرة بوفيه",
  "جزيرة مان",
  "جزيرة نورفوك",
  "جزيرة هيرد وجزر ماكدونالد",
  "جمهورية أفريقيا الوسطى",
  "جمهورية التشيك",
  "جمهورية الدومينيكان",
  "جنوب أفريقيا",
  "جواتيمالا",
  "جواديلوب",
  "جوام",
  "جورجيا",
  "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  "جيانا",
  "جيانا الفرنسية",
  "جيبوتي",
  "جيرسي",
  "جيرنزي",
  "دولة الفاتيكان",
  "دومينيكا",
  "رواندا",
  "روسيا",
  "رومانيا",
  "ريونيون",
  "زامبيا",
  "زيمبابوي",
  "ساموا",
  "سان مارينو",
  "سانت بيير وميكولون",
  "سانت فينسنت وجرينادينز",
  "سانت كيتس ونيفيس",
  "سانت لوشيا",
  "سانت هيلينا",
  "ساوتوماي وبرينسيبا",
  "سفالبارد وجان ماين",
  "سلوفاكيا",
  "سلوفينيا",
  "سنغافورة",
  "سوازيلاند",
  "سوريا",
  "سورينام",
  "سويسرا",
  "سيراليون",
  "سيريلانكا",
  "سيشل",
  "صربيا",
  "طاجيكستان",
  "عمان",
  "غانا",
  "غرينادا",
  "غينيا",
  "غينيا الاستوائية",
  "غينيا بيساو",
  "فانواتو",
  "فرنسا",
  "فنزويلا",
  "فلسطين",
  "فنلندا",
  "فيتنام",
  "قبرص",
  "قطر",
  "قيرقيزستان",
  "كازاخستان",
  "كاليدونيا الجديدة",
  "كامبوديا",
  "كرواتيا",
  "كندا",
  "كوبا",
  "كوت ديفوار (ساحل العاج)",
  "كوريا",
  "كوريا الشمالية",
  "كوستاريكا",
  "كولومبيا",
  "كيريباتي",
  "كينيا",
  "لاتفيا",
  "لاوس",
  "لبنان",
  "لختنشتاين",
  "لوكسمبورج",
  "ليبيا",
  "ليبيريا",
  "ليتوانيا",
  "ليسوتو",
  "مارتينيك",
  "ماكاو",
  "ماكرونيزيا",
  "مالاوي",
  "مالطا",
  "مالي",
  "ماليزيا",
  "مايوت",
  "مدغشقر",
  "مصر",
  "مقدونيا، جمهورية يوغوسلافيا السابقة",
  "مملكة البحرين",
  "منغوليا",
  "موريتانيا",
  "موريشيوس",
  "موزمبيق",
  "مولدوفا",
  "موناكو",
  "مونتسيرات",
  "مونتينيغرو",
  "ميانمار",
  "ناميبيا",
  "ناورو",
  "نيبال",
  "نيجيريا",
  "نيكاراجوا",
  "نيوا",
  "نيوزيلندا",
  "هايتي",
  "هندوراس",
  "هولندا",
  "هونغ كونغ SAR",
  "واليس وفوتونا",
];

export const countries_obj = [
  { code: "AD", ar: "أندورا", en: "Andorra" },
  {
    code: "AE",
    ar: "الامارات العربية المتحدة",
    en: "United Arab Emirates",
  },
  { code: "AF", ar: "أفغانستان", en: "Afghanistan" },
  { code: "AG", ar: "أنتيجوا وبربودا", en: "Antigua and Barbuda" },
  { code: "AI", ar: "أنجويلا", en: "Anguilla" },
  { code: "AL", ar: "ألبانيا", en: "Albania" },
  { code: "AM", ar: "أرمينيا", en: "Armenia" },
  { code: "AO", ar: "أنجولا", en: "Angola" },
  { code: "AQ", ar: "القطب الجنوبي", en: "Antarctica" },
  { code: "AR", ar: "الأرجنتين", en: "Argentina" },
  { code: "AS", ar: "ساموا الأمريكية", en: "American Samoa" },
  { code: "AT", ar: "النمسا", en: "Austria" },
  { code: "AU", ar: "أستراليا", en: "Australia" },
  { code: "AW", ar: "آروبا", en: "Aruba" },
  { code: "AX", ar: "جزر أولان", en: "Åland Islands" },
  { code: "AZ", ar: "أذربيجان", en: "Azerbaijan" },
  { code: "BA", ar: "البوسنة والهرسك", en: "Bosnia and Herzegovina" },
  { code: "BB", ar: "بربادوس", en: "Barbados" },
  { code: "BD", ar: "بنجلاديش", en: "Bangladesh" },
  { code: "BE", ar: "بلجيكا", en: "Belgium" },
  { code: "BF", ar: "بوركينا فاسو", en: "Burkina Faso" },
  { code: "BG", ar: "بلغاريا", en: "Bulgaria" },
  { code: "BH", ar: "البحرين", en: "Bahrain" },
  { code: "BI", ar: "بوروندي", en: "Burundi" },
  { code: "BJ", ar: "بنين", en: "Benin" },
  { code: "BL", ar: "سان بارتيلمي", en: "Saint Barthélemy" },
  { code: "BM", ar: "برمودا", en: "Bermuda" },
  { code: "BN", ar: "بروناي", en: "Brunei Darussalam" },
  { code: "BO", ar: "بوليفيا", en: "Bolivia (Plurinational State of)" },
  { code: "BQ", ar: "بونير", en: "Bonaire, Sint Eustatius and Saba" },
  { code: "BR", ar: "البرازيل", en: "Brazil" },
  { code: "BS", ar: "الباهاما", en: "Bahamas" },
  { code: "BT", ar: "بوتان", en: "Bhutan" },
  { code: "BV", ar: "جزيرة بوفيه", en: "Bouvet Island" },
  { code: "BW", ar: "بتسوانا", en: "Botswana" },
  { code: "BY", ar: "روسيا البيضاء", en: "Belarus" },
  { code: "BZ", ar: "بليز", en: "Belize" },
  { code: "CA", ar: "كندا", en: "Canada" },
  { code: "CC", ar: "جزر كوكوس", en: "Cocos (Keeling) Islands" },
  {
    code: "CD",
    ar: "جمهورية الكونغو الديمقراطية",
    en: "Congo (the Democratic Republic of the)",
  },
  {
    code: "CF",
    ar: "جمهورية افريقيا الوسطى",
    en: "Central African Republic",
  },
  { code: "CG", ar: "الكونغو - برازافيل", en: "Congo" },
  { code: "CH", ar: "سويسرا", en: "Switzerland" },
  { code: "CI", ar: "ساحل العاج", en: "Côte d'Ivoire" },
  { code: "CK", ar: "جزر كوك", en: "Cook Islands" },
  { code: "CL", ar: "شيلي", en: "Chile" },
  { code: "CM", ar: "الكاميرون", en: "Cameroon" },
  { code: "CN", ar: "الصين", en: "China" },
  { code: "CO", ar: "كولومبيا", en: "Colombia" },
  { code: "CR", ar: "كوستاريكا", en: "Costa Rica" },
  { code: "CU", ar: "كوبا", en: "Cuba" },
  { code: "CV", ar: "الرأس الأخضر", en: "Cabo Verde" },
  { code: "CW", ar: "كوراساو", en: "Curaçao" },
  { code: "CX", ar: "جزيرة الكريسماس", en: "Christmas Island" },
  { code: "CY", ar: "قبرص", en: "Cyprus" },
  { code: "CZ", ar: "جمهورية التشيك", en: "Czechia" },
  { code: "DE", ar: "ألمانيا", en: "Germany" },
  { code: "DJ", ar: "جيبوتي", en: "Djibouti" },
  { code: "DK", ar: "الدانمرك", en: "Denmark" },
  { code: "DM", ar: "دومينيكا", en: "Dominica" },
  { code: "DO", ar: "جمهورية الدومينيك", en: "Dominican Republic" },
  { code: "DZ", ar: "الجزائر", en: "Algeria" },
  { code: "EC", ar: "الاكوادور", en: "Ecuador" },
  { code: "EE", ar: "استونيا", en: "Estonia" },
  { code: "EG", ar: "مصر", en: "Egypt" },
  { code: "EH", ar: "الصحراء الغربية", en: "Western Sahara" },
  { code: "ER", ar: "اريتريا", en: "Eritrea" },
  { code: "ES", ar: "أسبانيا", en: "Spain" },
  { code: "ET", ar: "اثيوبيا", en: "Ethiopia" },
  { code: "FI", ar: "فنلندا", en: "Finland" },
  { code: "FJ", ar: "فيجي", en: "Fiji" },
  { code: "FK", ar: "جزر فوكلاند", en: "Falkland Islands [Malvinas]" },
  { code: "FM", ar: "ميكرونيزيا", en: "Micronesia (Federated States of)" },
  { code: "FO", ar: "جزر فارو", en: "Faroe Islands" },
  { code: "FR", ar: "فرنسا", en: "France" },
  { code: "GA", ar: "الجابون", en: "Gabon" },
  {
    code: "GB",
    ar: "المملكة المتحدة",
    en: "United Kingdom of Great Britain and Northern Ireland",
  },
  { code: "GD", ar: "جرينادا", en: "Grenada" },
  { code: "GE", ar: "جورجيا", en: "Georgia" },
  { code: "GF", ar: "غويانا", en: "French Guiana" },
  { code: "GG", ar: "غيرنزي", en: "Guernsey" },
  { code: "GH", ar: "غانا", en: "Ghana" },
  { code: "GI", ar: "جبل طارق", en: "Gibraltar" },
  { code: "GL", ar: "جرينلاند", en: "Greenland" },
  { code: "GM", ar: "غامبيا", en: "Gambia" },
  { code: "GN", ar: "غينيا", en: "Guinea" },
  { code: "GP", ar: "جوادلوب", en: "Guadeloupe" },
  { code: "GQ", ar: "غينيا الاستوائية", en: "Equatorial Guinea" },
  { code: "GR", ar: "اليونان", en: "Greece" },
  {
    code: "GS",
    ar: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    en: "South Georgia and the South Sandwich Islands",
  },
  { code: "GT", ar: "جواتيمالا", en: "Guatemala" },
  { code: "GU", ar: "جوام", en: "Guam" },
  { code: "GW", ar: "غينيا بيساو", en: "Guinea-Bissau" },
  { code: "GY", ar: "غيانا", en: "Guyana" },
  { code: "HK", ar: "هونج كونج الصينية", en: "Hong Kong" },
  {
    code: "HM",
    ar: "جزيرة هيرد وماكدونالد",
    en: "Heard Island and McDonald Islands",
  },
  { code: "HN", ar: "هندوراس", en: "Honduras" },
  { code: "HR", ar: "كرواتيا", en: "Croatia" },
  { code: "HT", ar: "هايتي", en: "Haiti" },
  { code: "HU", ar: "المجر", en: "Hungary" },
  { code: "ID", ar: "اندونيسيا", en: "Indonesia" },
  { code: "IE", ar: "أيرلندا", en: "Ireland" },
  { code: "IL", ar: "اسرائيل", en: "Israel" },
  { code: "IM", ar: "جزيرة مان", en: "Isle of Man" },
  { code: "IN", ar: "الهند", en: "India" },
  {
    code: "IO",
    ar: "المحيط الهندي البريطاني",
    en: "British Indian Ocean Territory",
  },
  { code: "IQ", ar: "العراق", en: "Iraq" },
  { code: "IR", ar: "ايران", en: "Iran (Islamic Republic of)" },
  { code: "IS", ar: "أيسلندا", en: "Iceland" },
  { code: "IT", ar: "ايطاليا", en: "Italy" },
  { code: "JE", ar: "جيرسي", en: "Jersey" },
  { code: "JM", ar: "جامايكا", en: "Jamaica" },
  { code: "JO", ar: "الأردن", en: "Jordan" },
  { code: "JP", ar: "اليابان", en: "Japan" },
  { code: "KE", ar: "كينيا", en: "Kenya" },
  { code: "KG", ar: "قرغيزستان", en: "Kyrgyzstan" },
  { code: "KH", ar: "كمبوديا", en: "Cambodia" },
  { code: "KI", ar: "كيريباتي", en: "Kiribati" },
  { code: "KM", ar: "جزر القمر", en: "Comoros" },
  { code: "KN", ar: "سانت كيتس ونيفيس", en: "Saint Kitts and Nevis" },
  {
    code: "KP",
    ar: "كوريا الشمالية",
    en: "Korea (the Democratic People's Republic of)",
  },
  { code: "KR", ar: "كوريا الجنوبية", en: "Korea (the Republic of)" },
  { code: "KW", ar: "الكويت", en: "Kuwait" },
  { code: "KY", ar: "جزر الكايمن", en: "Cayman Islands" },
  { code: "KZ", ar: "كازاخستان", en: "Kazakhstan" },
  { code: "LA", ar: "لاوس", en: "Lao People's Democratic Republic" },
  { code: "LB", ar: "لبنان", en: "Lebanon" },
  { code: "LC", ar: "سانت لوسيا", en: "Saint Lucia" },
  { code: "LI", ar: "ليختنشتاين", en: "Liechtenstein" },
  { code: "LK", ar: "سريلانكا", en: "Sri Lanka" },
  { code: "LR", ar: "ليبيريا", en: "Liberia" },
  { code: "LS", ar: "ليسوتو", en: "Lesotho" },
  { code: "LT", ar: "ليتوانيا", en: "Lithuania" },
  { code: "LU", ar: "لوكسمبورج", en: "Luxembourg" },
  { code: "LV", ar: "لاتفيا", en: "Latvia" },
  { code: "LY", ar: "ليبيا", en: "Libya" },
  { code: "MA", ar: "المغرب", en: "Morocco" },
  { code: "MC", ar: "موناكو", en: "Monaco" },
  { code: "MD", ar: "مولدافيا", en: "Moldova (the Republic of)" },
  { code: "ME", ar: "الجبل الأسود", en: "Montenegro" },
  { code: "MF", ar: "سانت مارتين", en: "Saint Martin (French part)" },
  { code: "MG", ar: "مدغشقر", en: "Madagascar" },
  { code: "MH", ar: "جزر المارشال", en: "Marshall Islands" },
  { code: "MK", ar: "مقدونيا", en: "Republic of North Macedonia" },
  { code: "ML", ar: "مالي", en: "Mali" },
  { code: "MM", ar: "ميانمار", en: "Myanmar" },
  { code: "MN", ar: "منغوليا", en: "Mongolia" },
  { code: "MO", ar: "ماكاو الصينية", en: "Macao" },
  {
    code: "MP",
    ar: "جزر ماريانا الشمالية",
    en: "Northern Mariana Islands",
  },
  { code: "MQ", ar: "مارتينيك", en: "Martinique" },
  { code: "MR", ar: "موريتانيا", en: "Mauritania" },
  { code: "MS", ar: "مونتسرات", en: "Montserrat" },
  { code: "MT", ar: "مالطا", en: "Malta" },
  { code: "MU", ar: "موريشيوس", en: "Mauritius" },
  { code: "MV", ar: "جزر الملديف", en: "Maldives" },
  { code: "MW", ar: "ملاوي", en: "Malawi" },
  { code: "MX", ar: "المكسيك", en: "Mexico" },
  { code: "MY", ar: "ماليزيا", en: "Malaysia" },
  { code: "MZ", ar: "موزمبيق", en: "Mozambique" },
  { code: "NA", ar: "ناميبيا", en: "Namibia" },
  { code: "NC", ar: "كاليدونيا الجديدة", en: "New Caledonia" },
  { code: "NE", ar: "النيجر", en: "Niger" },
  { code: "NF", ar: "جزيرة نورفوك", en: "Norfolk Island" },
  { code: "NG", ar: "نيجيريا", en: "Nigeria" },
  { code: "NI", ar: "نيكاراجوا", en: "Nicaragua" },
  { code: "NL", ar: "هولندا", en: "Netherlands" },
  { code: "NO", ar: "النرويج", en: "Norway" },
  { code: "NP", ar: "نيبال", en: "Nepal" },
  { code: "NR", ar: "نورو", en: "Nauru" },
  { code: "NU", ar: "نيوي", en: "Niue" },
  { code: "NZ", ar: "نيوزيلاندا", en: "New Zealand" },
  { code: "OM", ar: "عمان", en: "Oman" },
  { code: "PA", ar: "بنما", en: "Panama" },
  { code: "PE", ar: "بيرو", en: "Peru" },
  { code: "PF", ar: "بولينيزيا الفرنسية", en: "French Polynesia" },
  { code: "PG", ar: "بابوا غينيا الجديدة", en: "Papua New Guinea" },
  { code: "PH", ar: "الفيلبين", en: "Philippines" },
  { code: "PK", ar: "باكستان", en: "Pakistan" },
  { code: "PL", ar: "بولندا", en: "Poland" },
  { code: "PM", ar: "سانت بيير وميكولون", en: "Saint Pierre and Miquelon" },
  { code: "PN", ar: "بتكايرن", en: "Pitcairn" },
  { code: "PR", ar: "بورتوريكو", en: "Puerto Rico" },
  { code: "PS", ar: "فلسطين", en: "Palestine, State of" },
  { code: "PT", ar: "البرتغال", en: "Portugal" },
  { code: "PW", ar: "بالاو", en: "Palau" },
  { code: "PY", ar: "باراجواي", en: "Paraguay" },
  { code: "QA", ar: "قطر", en: "Qatar" },
  { code: "RE", ar: "روينيون", en: "Réunion" },
  { code: "RO", ar: "رومانيا", en: "Romania" },
  { code: "RS", ar: "صربيا", en: "Serbia" },
  { code: "RU", ar: "روسيا", en: "Russian Federation" },
  { code: "RW", ar: "رواندا", en: "Rwanda" },
  { code: "SA", ar: "المملكة العربية السعودية", en: "Saudi Arabia" },
  { code: "SB", ar: "جزر سليمان", en: "Solomon Islands" },
  { code: "SC", ar: "سيشل", en: "Seychelles" },
  { code: "SD", ar: "السودان", en: "Sudan" },
  { code: "SE", ar: "السويد", en: "Sweden" },
  { code: "SG", ar: "سنغافورة", en: "Singapore" },
  {
    code: "SH",
    ar: "سانت هيلنا",
    en: "Saint Helena, Ascension and Tristan da Cunha",
  },
  { code: "SI", ar: "سلوفينيا", en: "Slovenia" },
  { code: "SJ", ar: "سفالبارد وجان مايان", en: "Svalbard and Jan Mayen" },
  { code: "SK", ar: "سلوفاكيا", en: "Slovakia" },
  { code: "SL", ar: "سيراليون", en: "Sierra Leone" },
  { code: "SM", ar: "سان مارينو", en: "San Marino" },
  { code: "SN", ar: "السنغال", en: "Senegal" },
  { code: "SO", ar: "الصومال", en: "Somalia" },
  { code: "SR", ar: "سورينام", en: "Suriname" },
  { code: "SS", ar: "جنوب السودان", en: "South Sudan" },
  { code: "ST", ar: "ساو تومي وبرينسيبي", en: "Sao Tome and Principe" },
  { code: "SV", ar: "السلفادور", en: "El Salvador" },
  { code: "SX", ar: "سينت مارتن", en: "Sint Maarten (Dutch part)" },
  { code: "SY", ar: "سوريا", en: "Syrian Arab Republic" },
  { code: "SZ", ar: "سوازيلاند", en: "Eswatini" },
  { code: "TC", ar: "جزر الترك وجايكوس", en: "Turks and Caicos Islands" },
  { code: "TD", ar: "تشاد", en: "Chad" },
  {
    code: "TF",
    ar: "المقاطعات الجنوبية الفرنسية",
    en: "French Southern Territories",
  },
  { code: "TG", ar: "توجو", en: "Togo" },
  { code: "TH", ar: "تايلند", en: "Thailand" },
  { code: "TJ", ar: "طاجكستان", en: "Tajikistan" },
  { code: "TK", ar: "توكيلو", en: "Tokelau" },
  { code: "TL", ar: "تيمور الشرقية", en: "Timor-Leste" },
  { code: "TM", ar: "تركمانستان", en: "Turkmenistan" },
  { code: "TN", ar: "تونس", en: "Tunisia" },
  { code: "TO", ar: "تونجا", en: "Tonga" },
  { code: "TR", ar: "تركيا", en: "Turkey" },
  { code: "TT", ar: "ترينيداد وتوباغو", en: "Trinidad and Tobago" },
  { code: "TV", ar: "توفالو", en: "Tuvalu" },
  { code: "TW", ar: "تايوان", en: "Taiwan" },
  { code: "TZ", ar: "تانزانيا", en: "Tanzania, United Republic of" },
  { code: "UA", ar: "أوكرانيا", en: "Ukraine" },
  { code: "UG", ar: "أوغندا", en: "Uganda" },
  {
    code: "UM",
    ar: "جزر الولايات المتحدة البعيدة الصغيرة",
    en: "United States Minor Outlying Islands",
  },
  {
    code: "US",
    ar: "الولايات المتحدة الأمريكية",
    en: "United States of America",
  },
  { code: "UY", ar: "أورجواي", en: "Uruguay" },
  { code: "UZ", ar: "أوزبكستان", en: "Uzbekistan" },
  { code: "VA", ar: "الفاتيكان", en: "Holy See" },
  {
    code: "VC",
    ar: "سانت فنسنت وغرنادين",
    en: "Saint Vincent and the Grenadines",
  },
  { code: "VE", ar: "فنزويلا", en: "Venezuela (Bolivarian Republic of)" },
  { code: "VG", ar: "جزر فرجين البريطانية", en: "Virgin Islands (British)" },
  { code: "VI", ar: "جزر فرجين الأمريكية", en: "Virgin Islands (U.S.)" },
  { code: "VN", ar: "فيتنام", en: "Viet Nam" },
  { code: "VU", ar: "فانواتو", en: "Vanuatu" },
  { code: "WF", ar: "جزر والس وفوتونا", en: "Wallis and Futuna" },
  { code: "WS", ar: "ساموا", en: "Samoa" },
  { code: "XK", ar: "كوسوفو" },
  { code: "YE", ar: "اليمن", en: "Yemen" },
  { code: "YT", ar: "مايوت", en: "Mayotte" },
  { code: "ZA", ar: "جمهورية جنوب افريقيا", en: "South Africa" },
  { code: "ZM", ar: "زامبيا", en: "Zambia" },
  { code: "ZW", ar: "زيمبابوي", en: "Zimbabwe" },
];

export function getContractState() {
  return JSON.parse(sessionStorage.getItem(STORAGE));
}

export function setContractState(state) {
  if (!sessionStorage.getItem(STORAGE)) {
    sessionStorage.setItem(STORAGE, JSON.stringify(state));
    return;
  } else {
    const oldState = JSON.parse(sessionStorage.getItem(STORAGE));
    sessionStorage.setItem(STORAGE, JSON.stringify({ ...oldState, ...state }));
  }
}

export const removeSessionStorage = () => {
  sessionStorage.removeItem(STORAGE);
};
