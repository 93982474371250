import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "residential-form",
  initialState: {
    residential_name: "",
    state_id: "",
    directorate_id: "",
    village_id: "",
    sector_id: "",
    hod_id: "",
    piece_number: "",
    registration_number: "",
    street_name: "",
    building_number: "",
    apartment_number: "",
    floor: "",
    closest_place: "",
    land_type_id: "",
    residential_state: "not-furnished",

    is_there_safe_item: "false",
    safe_item: "",
    property_building_year: "",
    total_count_of_apartments: "",
    value_of_roof_tax: "",
    registration_document_image: "",
    is_video: "false",
    item_video: "",

    is_edit: "false",
    is_furnished_edit: "false",

    registration_document_image_url: "",
    video_url: "",
    apartment_images_url: [],

    furnished_items: {},
    deleted_furnished_items_ids: [],
  },
  reducers: {
    // actions => action handlers
    setResidentialInputValue: (state, action) => {
      // if vlaue changed, call update
      if (
        action.payload?.key === "registration_document_image" ||
        action.payload?.key === "item_state" ||
        action.payload?.key === "item_name" ||
        action.payload?.key === "item_description" ||
        action.payload?.key === "apartment_images_url"
      ) {
      } else {
        state.is_edit = "true";
      }

      // if vlaue changed, call update
      if (
        action.payload?.key === "item_state" ||
        action.payload?.key === "item_name" ||
        action.payload?.key === "item_description"
      ) {
        state.is_furnished_edit = "true";
      }
      state[action.payload?.key] = action.payload?.value;
    },
    resetResidentialInputsValues: (state, action) => {
      state.residential_name = "";
      state.state_id = "";
      state.directorate_id = "";
      state.village_id = "";
      state.sector_id = "";
      state.hod_id = "";
      state.piece_number = "";
      state.registration_number = "";
      state.registration_document_image = "";
      state.street_name = "";
      state.building_number = "";
      state.apartment_number = "";
      state.floor = "";
      state.closest_place = "";
      state.land_type_id = "";
      state.residential_state = "not-furnished";
      state.is_video = "false";
      state.item_video = "";
      state.is_there_safe_item = "false";
      state.safe_item = "";
      state.property_building_year = "";
      state.total_count_of_apartments = "";
      state.value_of_roof_tax = "";

      state.is_edit = "false";
      state.is_furnished_edit = "false";

      state.registration_document_image_url = "";
      state.apartment_images_url = [];
      state.furnished_items = {};
      state.deleted_furnished_items_ids = [];
    },
    setResidentialDataToEdit: (state, action) => {
      state.residential_profile_id =
        action.payload?.residential_profile_id || "";
      state.residential_name = action.payload?.name || "";

      state.state_id = String(action.payload?.state?.state_id) || "";
      state.directorate_id =
        String(action.payload?.directorate?.directorate_id) || "";
      state.village_id = String(action.payload?.village?.village_id) || "";
      state.sector_id = String(action.payload?.sector?.sector_id) || "";
      state.hod_id = String(action.payload?.hod?.hod_id) || "";
      state.piece_number = action.payload?.piece_number || "";
      state.registration_number = action.payload?.registration_number || "";
      state.registration_document_image = "";
      state.street_name = action.payload?.street_name || "";
      state.building_number = action.payload?.building_number || "";
      state.apartment_number = action.payload?.apartment_number || "";
      state.floor = action.payload?.floor || "";
      state.closest_place = action.payload?.closest_place || "";
      state.land_type_id = action.payload?.land_type?.land_type_id || "";

      state.residential_state = action.payload?.residential_state || "";
      state.current_residential_state = action.payload?.residential_state || "";

      state.is_video = action.payload?.is_video || "";
      state.item_video = action.payload?.item_video || "";

      state.is_there_safe_item = action.payload?.safe_items ? "true" : "false";
      state.safe_item = action.payload?.safe_items || "";
      state.property_building_year =
        action.payload?.property_building_year || "";
      state.total_count_of_apartments =
        action.payload?.total_count_of_apartments || "";
      state.value_of_roof_tax = action.payload?.value_of_roof_tax || "";

      state.is_video = 
        (action.payload?.asset_file || []).filter(
          (asset) => asset.post_specification === "furnished_video"
        ).length !== 0
          ? "true"
          : "false";

      state.video_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "furnished_video"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "furnished_video"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "furnished_video"
          )[0]?.file_id || "",
      }

      state.registration_document_image_url = 
      {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "registration_document"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "registration_document"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "registration_document"
          )[0]?.file_id || "",
      }

      state.apartment_images_url = 
        (action.payload?.asset_file || []).filter(
          (asset) => asset.post_specification === "apartment_image"
        ) || [];
    },
    addFurnishedItem: (state, action) => {
      state.furnished_items[action.payload?.id] = {
        id: action.payload?.id,
        is_edit: false,
        item_name: "",
        item_state: "excellent",
        item_description: "",
      };
    },
    addFurnishedItemDataToEdit: (state, action) => {
      state.furnished_items[action.payload?.id] = {
        id: action.payload?.id,
        furnished_item_id: action.payload?.data?.furnished_item_id || "",
        is_edit: false,
        item_name: action.payload?.data?.item_name || "",
        item_state: action.payload?.data?.item_state || "",
        item_description: action.payload?.data?.item_description || "",
      };
    },
    removeFurnishedItem: (state, action) => {
      if(state.furnished_items[action.payload?.id]["furnished_item_id"]) {
        state.deleted_furnished_items_ids.push(
          state.furnished_items[action.payload?.id]["furnished_item_id"] || ""
        );
      }
      delete state.furnished_items[action.payload?.id];
    },
    addFurnishedItemData: (state, action) => {
      state.furnished_items[action.payload?.id]["is_edit"] = true;
      state.furnished_items[action.payload?.id][action.payload?.key] =
        action.payload?.value;
    },
    removeAllFurnishedItems: (state, action) => {
      state.furnished_items = {};
    },
  },
});

export const {
  setResidentialInputValue,
  resetResidentialInputsValues,
  setResidentialDataToEdit,
  addFurnishedItem,
  removeFurnishedItem,
  addFurnishedItemData,
  removeAllFurnishedItems,
  addFurnishedItemDataToEdit,
} = slice.actions;
export default slice.reducer;
