import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "legal-form",
  initialState: {
    type_id: "",

    legal_terms: {},
    deleted_legal_terms_ids: [],
  },
  reducers: {
    // actions => action handlers
    setLegalTermProfileInputValue: (state, action) => {
      // if vlaue changed, call update
      state.is_edit = "true";
      state[action.payload?.key] = action.payload?.value;
    },
    resetLegalTermProfileInputsValues: (state, action) => {
      state.type_id = "";
      state.legal_terms = {};
      state.deleted_legal_terms_ids = [];
    },
    setLegalTermProfileDataToEdit: (state, action) => {
      state.legal_term_profile_id = action.payload?.legal_term_profile_id || "";
      state.type_id = String(action.payload?.type_id) || "";
    },
    addLegalTermsItem: (state, action) => {
      state.legal_terms[action.payload?.id] = {
        id: action.payload?.id,
        is_edit: false,
        title: "",
        description: "",
        position: action.payload?.position || 1,
      };
    },
    addLegalTermsDataToEdit: (state, action) => {
      state.legal_terms[action.payload?.id] = {
        id: action.payload?.id,
        legal_term_id: action.payload?.data?.legal_term_id || "",
        is_edit: false,
        title: action.payload?.data?.title || "",
        description: action.payload?.data?.description || "",
        position: action.payload?.data?.position || 1,
      };
    },
    removeLegalTermsItem: (state, action) => {
      if (state.legal_terms[action.payload?.id]["legal_term_id"]) {
        state.deleted_legal_terms_ids.push(
          state.legal_terms[action.payload?.id]["legal_term_id"] || ""
        );
      }
      delete state.legal_terms[action.payload?.id];
    },
    addLegalTermsItemData: (state, action) => {
      state.legal_terms[action.payload?.id]["is_edit"] = true;
      state.legal_terms[action.payload?.id][action.payload?.key] =
        action.payload?.value;
    },
    removeAllLegalTermsItems: (state, action) => {
      state.legal_terms = {};
    },
  },
});

export const {
  setLegalTermProfileInputValue,
  resetLegalTermProfileInputsValues,
  setLegalTermProfileDataToEdit,
  addLegalTermsItem,
  addLegalTermsDataToEdit,
  removeLegalTermsItem,
  addLegalTermsItemData,
  removeAllLegalTermsItems,
} = slice.actions;
export default slice.reducer;
