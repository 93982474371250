import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "individuals-form",
  initialState: {
    first_name: "",
    second_name: "",
    third_name: "",
    family_name: "",
    email: "",
    phone_number: "",
    address: "",
    nationality: "Jordan",
    is_residential: "false",
    is_corporate: "false",
    id_number: "",
    id_image: "",
    passport_number: "",
    passport_image: "",
    residential_number: "",
    residential_image: "",
    non_jordanian_serial_number: "",
    non_jordanian_serial_image: "",
    residential_serial_number: "",
    residential_serial_image: "",
    is_inheritance: "false",
    is_secondary_tenant: "false",
    is_edit: "false",

    family_book: "",


    id_image_url: "",
    passport_image_url: "",
    non_jordanian_serial_image_url: "",
    residential_serial_image_url: "",
    residential_image_url: "",
    family_book_url: "",

  },
  reducers: {
    // actions => action handlers
    setInputValue: (state, action) => {
      const skipEdit = [
        "is_corporate",
        "is_secondary_tenant",
        "id_image",
        "passport_image",
        "residential_image",
        "non_jordanian_serial_image",
        "residential_serial_image",
      ];
      if (skipEdit.includes(action.payload?.key)) {
      } else {
        state.is_edit = "true";
      }
      state[action.payload?.key] = action.payload?.value;
    },
    resetFileInputs: (state, action) => {
      state.id_image = "";
      state.passport_image = "";
      state.residential_image = "";
      state.non_jordanian_serial_image = "";
      state.residential_serial_image = "";
    },
    resetInputsValue: (state, action) => {
      state.first_name = "";
      state.second_name = "";
      state.third_name = "";
      state.family_name = "";
      state.email = "";
      state.phone_number = "";
      state.address = "";
      state.nationality = "Jordan";
      state.is_residential = "false";
      state.is_corporate = "false";
      state.id_number = "";
      state.id_image = "";
      state.passport_number = "";
      state.passport_image = "";
      state.residential_number = "";
      state.residential_image = "";
      state.non_jordanian_serial_number = "";
      state.non_jordanian_serial_image = "";
      state.residential_serial_number = "";
      state.residential_serial_image = "";
      state.is_inheritance = "false";
      state.is_secondary_tenant = "false";
      state.is_edit = "false";

      state.family_book = "";

      state.id_image_url = "";
      state.passport_image_url = "";
      state.non_jordanian_serial_image_url = "";
      state.residential_serial_image_url = "";
      state.residential_image_url = "";
      state.family_book_url = "";

    },
    setIndiviualDataToEdit: (state, action) => {
      state.landlord_account_id = action.payload?.landlord_account_id || "";
      state.tenant_account_id = action.payload?.tenant_account_id || "";
      state.profile_id = action.payload?.profile_id || "";

      state.current_nationality_type = action.payload?.nationality_type || "";
      state.is_corporate =
        action.payload?.is_corporate === 0 ? "false" : "true";
      state.is_secondary_tenant = action.payload?.tenant_secondary
        ? "true"
        : "false";

      state.first_name = action.payload?.first_name || "";
      state.second_name = action.payload?.father_name || "";
      state.third_name = action.payload?.grand_father_name || "";
      state.family_name = action.payload?.family_name || "";
      state.email = action.payload?.email || "";
      state.phone_number =
        action.payload?.country_code + action.payload?.phone_number || "";
      state.address = action.payload?.address || "";
      state.nationality = action.payload?.nationality || "";
      state.is_residential =
        action.payload?.nationality_type === "jo_nationality" ||
        action.payload?.nationality_type === "none_jo_nationality"
          ? "false"
          : "true";
      state.is_corporate = action.payload?.is_corporate || 0;
      state.is_inheritance =
        action.payload?.is_inheritance === 1 ? "true" : "false";
      state.non_jordanian_serial_number = action.payload?.serial_number || "";
      state.residential_serial_number = action.payload?.serial_number || "";

      state.id_number =
        action.payload?.nationality_type === "jo_nationality"
          ? action.payload?.government_number
          : "";
      state.passport_number =
        action.payload?.nationality_type === "none_jo_nationality"
          ? action.payload?.government_number
          : "";
      state.residential_number =
        action.payload?.nationality_type === "jo_residential"
          ? action.payload?.government_number
          : "";

      state.non_jordanian_serial_image = "";
      state.id_image = "";
      state.passport_image = "";
      state.residential_image = "";
      state.residential_serial_image = "";

      // handle images URLs
      state.id_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "nationality_id"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "nationality_id"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "nationality_id"
          )[0]?.file_id || "",
      };

      state.passport_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "passport"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "passport"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "passport"
          )[0]?.file_id || "",
      };

      state.non_jordanian_serial_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_id || "",
      };

      state.residential_serial_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "serial_number"
          )[0]?.file_id || "",
      };

      state.residential_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "residential"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "residential"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "residential"
          )[0]?.file_id || "",
      };

      state.family_book_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "family_book"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "family_book"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "family_book"
          )[0]?.file_id || "",
      };
    },
  },
});

export const {
  setInputValue,
  resetInputsValue,
  resetFileInputs,
  setIndiviualDataToEdit,
} = slice.actions;
export default slice.reducer;
