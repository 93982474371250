import { combineReducers } from "redux";
import userSessionReducer from "./userSession";
import heirsReducer from "./heirs";
import individualsForm from "./individuals-form";
import imageSlice from "./imageSlice";
import companiesForm from "./companies-form";
import secondaryTenantReducer from "./secondaryTenant";
import residentialFormReducer from "./residential-form";
import commercialFormReducer from "./commercial-form";
import commercialOfficeReducer from "./commercial-office";
import leasingInfoReducer from "./leasing-info";
import additionalsReducer from "./additionals";
import paymentsReducer from "./payments";
import propertyInfoReducer from "./property-info";
import userFromReducer from "./user-form";
import legalFormReducer from "./legal-form";

export default combineReducers({
  userSession: userSessionReducer,
  heirsData: heirsReducer,
  individualsForm: individualsForm,
  imageSlice: imageSlice,
  companiesForm: companiesForm,
  secondaryTenant: secondaryTenantReducer,
  residentialForm: residentialFormReducer,
  commercialForm: commercialFormReducer,
  commercialOffice: commercialOfficeReducer,
  leasingInfo: leasingInfoReducer,
  additionalsInfo: additionalsReducer,
  paymentsInfo: paymentsReducer,
  propertyInfo: propertyInfoReducer,
  userForm: userFromReducer,
  legalForm: legalFormReducer,
});
