import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "companies-form",
  initialState: {
    is_corporate: "",
    corporate_name: "",
    corporate_sign_name: "",
    email: "",
    phone_number: "",
    address: "",
    commercial_registration_number: "",
    commercial_registration_image: "",

    commercial_registration_image_url: "",
    is_edit: "false",
  },
  reducers: {
    // actions => action handlers
    setCompInputValue: (state, action) => {
      if(action.payload?.key !== "commercial_registration_image"){
        state.is_edit = "true";
      }
      state[action.payload?.key] = action.payload?.value;
    },
    resetFileInputs: (state, action) => {
      state.commercial_registration_image = "";
    },
    resetCompInputsValue: (state, action) => {
      state.is_corporate =  "";
      state.corporate_name= "";
      state.corporate_sign_name =  "";
      state.email =  "";
      state.phone_number =  "";
      state.address =  "";
      state.commercial_registration_number =  "";
      state.commercial_registration_image =  "";
      state.commercial_registration_image_url = "";
      state.is_edit = "false";

    },
    setCompDataToEdit: (state, action) => {
      state.landlord_account_id = action.payload?.landlord_account_id || "";
      state.is_corporate =  action.payload?.is_corporate || "";
      state.corporate_name =  action.payload?.corporate_name || "";
      state.corporate_sign_name =  action.payload?.corporate_sign_name || "";
      state.email =  action.payload?.email || "";
      state.phone_number = (action.payload?.country_code + action.payload?.phone_number) || "";
      state.address =  action.payload?.address || "";
      state.commercial_registration_number =  action.payload?.government_number || "";

      state.commercial_registration_image_url = {
        url:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_url || "",
        file_type:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_type || "",
          file_id:
          (action.payload?.asset_file || []).filter(
            (asset) => asset.post_specification === "corporate_profile"
          )[0]?.file_id || "",
      }
    }
  },
});

export const { setCompInputValue, resetCompInputsValue, setCompDataToEdit, resetFileInputs } =
  slice.actions;
export default slice.reducer;
