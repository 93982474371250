import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "commercialOffice",
  initialState: {
    current_commercial_office_id: [],
    commercial_offices: {},
    deleted_commercial_office_ids: [],
  },
  reducers: {
    // actions => action handlers
    addCommercialOfficeForm: (state, action) => {
      state.commercial_offices[action.payload?.id] = {
        id: action.payload?.id,
        commercial_profile_id: "",
        floor: "",
        office_number: "",
        area_size: "",
        land_type_id: "31",

        residential_state: "not-furnished",
        item_name: "",
        item_state: "excellent",
        item_description: "",

        is_there_safe_item: "false",
        safe_item: "",

        is_video: "false",
        item_video: "",

        video_url: "",

        is_edit: "false",
        is_furnished_edit: "false",

        apartment_images: [],
        apartment_images_url: [],
        sketch_chart_image: "",
        sketch_chart_image_url: "",

        furnished_items: {},
        deleted_furnished_items_ids: [],
      };
    },
    removeCommercialOfficeForm: (state, action) => {
      if(state.commercial_offices[action.payload?.id]["commercial_office_id"]) {
        state.deleted_commercial_office_ids.push(
          state.commercial_offices[action.payload?.id]["commercial_office_id"] || ""
        );
      }
      
      delete state.commercial_offices[action.payload?.id];
    },
    addCommercialOfficeFormData: (state, action) => {
      if (
        action.payload?.key === "item_state" ||
        action.payload?.key === "item_name" ||
        action.payload?.key === "item_description" ||
        action.payload?.key === "apartment_images_url" ||
        action.payload?.key === "apartment_images" ||
        action.payload?.key === "sketch_chart_image"
      ) {
      } else {
        state.commercial_offices[action.payload?.id]["is_edit"] = "true";
      }
      // if vlaue changed, call update
      if (
        action.payload?.key === "item_state" ||
        action.payload?.key === "item_name" ||
        action.payload?.key === "item_description"
      ) {
        state.commercial_offices[action.payload?.id]["is_furnished_edit"] =
          "true";
      }

      state.commercial_offices[action.payload?.id][action.payload?.key] =
        action.payload?.value;
    },
    resetCommercialOfficeForms: (state) => {
      state.commercial_offices = {};
      state.deleted_commercial_office_ids = []
    },
    addCommercialOfficeFormDataToEdit: (state, action) => {
      state.commercial_offices[action.payload?.id] = {
        id: action.payload?.id,
        commercial_office_id: action.payload?.data?.commercial_office_id || "",
        commercial_profile_id:
          action.payload?.data?.commercial_profile_id || "",

        floor: action.payload?.data?.floor || "",
        office_number: action.payload?.data?.office_number || "",
        area_size: action.payload?.data?.area_size || "",
        land_type_id: action.payload?.data?.land_type?.land_type_id || "",

        residential_state: action.payload?.data?.residential_state || "",
        current_residential_state:
          action.payload?.data?.residential_state || "",

        item_name: action.payload?.data?.furnished_items
          ? action.payload?.data?.furnished_items[0]?.item_name
          : "",
        item_state: action.payload?.data?.furnished_items
          ? action.payload?.data?.furnished_items[0]?.item_state
          : "excellent",
        item_description: action.payload?.data?.furnished_items
          ? action.payload?.data?.furnished_items[0]?.item_description
          : "",
        furnished_item_id: action.payload?.data?.furnished_items
          ? action.payload?.data?.furnished_items[0]?.furnished_item_id
          : "",

        is_edit: "false",

        furnished_items: {},
        deleted_furnished_items_ids: [],

        is_there_safe_item: action.payload?.data?.safe_items ? "true" : "false",
        safe_item: action.payload?.data?.safe_items || "",
        // handle images URLs
        apartment_images_url:
          (action.payload?.data?.asset_file_commercial_office || []).filter(
            (asset) => asset.post_specification === "apartment_image"
          ) || [],

        video_url: {
          url:
            (action.payload?.data?.asset_file_commercial_office || []).filter(
              (asset) => asset.post_specification === "furnished_video"
            )[0]?.file_url || "",
          file_type:
            (action.payload?.data?.asset_file_commercial_office || []).filter(
              (asset) => asset.post_specification === "furnished_video"
            )[0]?.file_type || "",
            file_id:
          (action.payload?.data?.asset_file_commercial_office || []).filter(
            (asset) => asset.post_specification === "furnished_video"
          )[0]?.file_id || "",
        },

        sketch_chart_image_url: {
          url:
            (action.payload?.data?.asset_file_commercial_office || []).filter(
              (asset) => asset.post_specification === "sketch_chart"
            )[0]?.file_url || "",
          file_type:
            (action.payload?.data?.asset_file_commercial_office || []).filter(
              (asset) => asset.post_specification === "sketch_chart"
            )[0]?.file_type || "",
            file_id:
          (action.payload?.data?.asset_file_commercial_office || []).filter(
            (asset) => asset.post_specification === "sketch_chart"
          )[0]?.file_id || "",
        },

        is_video:
          (action.payload?.data?.asset_file_commercial_office || []).filter(
            (asset) => asset.post_specification === "furnished_video"
          ).length !== 0
            ? "true"
            : "false",
      };
    },
    setCurrentCommercialOfficeIds: (state, action) => {
      state.current_commercial_office_id =
        (action.payload?.commercial_offices || []).map(
          (elem) => elem.commercial_office_id
        ) || [];
    },
    addFurnishedItem: (state, action) => {
      state.commercial_offices[action.payload?.office_id].furnished_items[action.payload?.furnished_id] = {
        id: action.payload?.furnished_id,
        is_edit: false,
        item_name: "",
        item_state: "excellent",
        item_description: "",
      };
    },
    addFurnishedItemData: (state, action) => {
      state.commercial_offices[action.payload?.office_id].furnished_items[action.payload?.id]["is_edit"] = true;
      state.commercial_offices[action.payload?.office_id].furnished_items[action.payload?.id][action.payload?.key] =
        action.payload?.value;
    },
    addFurnishedItemDataToEdit: (state, action) => {
      state.commercial_offices[action.payload?.office_id].furnished_items[action.payload?.furnished_id] = {
        id: action.payload?.furnished_id,
        furnished_item_id: action.payload?.data?.furnished_item_id || "",
        is_edit: false,
        item_name: action.payload?.data?.item_name || "",
        item_state: action.payload?.data?.item_state || "",
        item_description: action.payload?.data?.item_description || "",
      };
    },
    removeFurnishedItem: (state, action) => {
      if(state.commercial_offices[action.payload?.office_id].furnished_items[action.payload?.furnished_id]["furnished_item_id"]) {
        state.commercial_offices[action.payload?.office_id].deleted_furnished_items_ids.push(
          state.commercial_offices[action.payload?.office_id].furnished_items[action.payload?.furnished_id]["furnished_item_id"] || ""
        );
      }
      delete state.commercial_offices[action.payload?.office_id].furnished_items[action.payload?.furnished_id];
    },
    
    removeAllFurnishedItems: (state, action) => {
      state.furnished_items = {};
    },
  },
});

export const {
  addCommercialOfficeForm,
  removeCommercialOfficeForm,
  addCommercialOfficeFormData,
  resetCommercialOfficeForms,
  addCommercialOfficeFormDataToEdit,
  setCurrentCommercialOfficeIds,
  addFurnishedItem,
  addFurnishedItemDataToEdit,
  removeFurnishedItem,
  addFurnishedItemData,
  removeAllFurnishedItems
} = slice.actions;
export default slice.reducer;
