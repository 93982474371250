import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "userSession",
  initialState: { isLogin: false, role: "" },
  reducers: {
    // actions => action handlers
    setLoginState: (state, action) => {
      state.isLogin = true;
      state.role = action.payload?.value || "course";
    },
    removeLoginState: (state, action) => {
      state.isLogin = false;
      state.role = "";
    },
  },
});

export const { setLoginState, removeLoginState } = slice.actions;
export default slice.reducer;
