import axios from "axios";
import { handleError } from "./error";
import { toastCreate } from "../utils/global";

const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  credentials: "include",
});

publicAxios.interceptors.response.use(
  (res) => {
    return res;
  },

  async (err) => {
    toastCreate("error", handleError(err));
    return Promise.reject(err);
  }
);

const method = {
  get: publicAxios.get,
  post: publicAxios.post,
  put: publicAxios.put,
  delete: publicAxios.delete,
  CancelToken: publicAxios.CancelToken,
};

export default method;
