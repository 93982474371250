import React, { useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import { userLogout, setLocalStorage, getLocalStorage } from "./utils/global";
import Router from "./router";
import { Header, Footer } from "./components";
import { removeLoginState } from "./redux/userSession";
import "./i18n";

import { refreshToken } from "./services/user/auth";

import "react-phone-number-input/style.css";
import "./App.scss";

function App() {
  // get the current language
  const currentLanguageCode = localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin } = useSelector((state) => state.userSession);

  // to update the dir of html Dom
  useEffect(() => {
    document.querySelector("html").dir =
      currentLanguageCode === "ar" ? "rtl" : "ltr";
    document.querySelector("html").lang = currentLanguageCode;
  }, [currentLanguageCode]);

  // set interval for refresh token functionality
  useEffect(() => {
    if (isLogin) {
      const interval = setInterval(async () => {
        try {
          const result = await refreshToken();
          const aqdeeStorage = getLocalStorage();
          aqdeeStorage.user_info["token"] =
            result?.response?.message?.new_token;
          setLocalStorage(aqdeeStorage);
        } catch (e) {
          userLogout(dispatch, removeLoginState(), navigate);
        }
      }, 3600000); // Every 60 min
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  return (
    <div className="mainDiv">
      <Header />
      <main className="main">
        <React.Suspense
          fallback={
            <div className="main main-loader">
              <BeatLoader
                loading={true}
                size={25}
                css={{
                  display: "block",
                  margin: "0 auto",
                }}
                color="#e73137"
              />
            </div>
          }
        >
          <Router />
          <ToastContainer />
        </React.Suspense>
      </main>
      <Footer />
    </div>
  );
}

export default App;
