import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "secondaryTenant",
  initialState: {
    current_secondary_tenant_id : [],
    secondaryTenantForms: {},
  },
  reducers: {
    // actions => action handlers
    addSecondaryTenantForm: (state, action) => {
      state.secondaryTenantForms[action.payload?.id] = {
        id: action.payload?.id,
        tenant_account_id: "",
        first_name: "",
        second_name: "",
        third_name: "",
        family_name: "",
        email: "",
        phone_number: "",
        address: "",
        nationality: "Jordan",
        is_residential: "false",
        id_number: "",
        id_image: "",
        passport_number: "",
        passport_image: "",
        non_jordanian_serial_number: "",
        non_jordanian_serial_image: "",
        residential_number: "",
        residential_image: "",
        residential_serial_number: "",
        residential_serial_image: "",
        is_phone_number: " ",

        is_edit: "false",
        id_image_url: "",
        passport_image_url: "",
        non_jordanian_serial_image_url: "",
        residential_serial_image_url: "",
        residential_image_url: "",
      };
    },
    removeSecondaryTenantForm: (state, action) => {
      delete state.secondaryTenantForms[action.payload?.id];
    },
    addSecondaryTenantFormData: (state, action) => {
      state.secondaryTenantForms[action.payload?.id]["is_edit"] = "true";
      state.secondaryTenantForms[action.payload?.id][action.payload?.key] =
        action.payload?.value;
    },
    resetSecondaryTenantForms: (state) => {
      state.secondaryTenantForms = {};
    },
    addSecondaryTenantFormDataToEdit: (state, action) => {
      state.secondaryTenantForms[action.payload?.id] = {
        id: action.payload?.id,
        tenant_account_id: action.payload?.data?.tenant_account_id || "",
        current_nationality_type : action.payload?.data?.nationality_type || "",
        tenant_secondary_id : action.payload?.data?.tenant_secondary_id || "",
        first_name: action.payload?.data?.first_name || "",
        second_name: action.payload?.data?.father_name || "",
        third_name: action.payload?.data?.grand_father_name || "",
        family_name: action.payload?.data?.family_name || "",
        email: action.payload?.data?.email || "",
        phone_number:
          action.payload?.data?.country_code +
            action.payload?.data?.phone_number || "",
        address: action.payload?.data?.address || "",
        nationality: action.payload?.data?.nationality || "Jordan",
        is_residential:
        (action.payload?.data?.nationality_type === "jo_nationality" || action.payload?.data?.nationality_type === "none_jo_nationality") ? "false" : "true",
        is_corporate: action.payload?.data?.is_corporate || 0,
        non_jordanian_serial_number: action.payload?.data?.serial_number || "",
        residential_serial_number: action.payload?.data?.serial_number || "",
        id_number:
          action.payload?.data?.nationality_type === "jo_nationality"
            ? action.payload?.data?.government_number
            : "",
        passport_number:
          action.payload?.data?.nationality_type === "none_jo_nationality"
            ? action.payload?.data?.government_number
            : "",
        residential_number:
          action.payload?.data?.nationality_type === "jo_residential"
            ? action.payload?.data?.government_number
            : "",
        non_jordanian_serial_image: "",
        id_image: "",
        passport_image: "",
        residential_image: "",
        residential_serial_image: "",
        is_phone_number: " ",

        // handle images URLs
        id_image_url:
          ((action.payload?.data?.asset_file_tenant_secondary || []).filter(
            (asset) => asset.post_specification === "nationality_id"
          ))[0]?.file_url || "",

        passport_image_url:
          ((action.payload?.data?.asset_file_tenant_secondary || []).filter(
            (asset) => asset.post_specification === "passport"
          ))[0]?.file_url || "",

        non_jordanian_serial_image_url:
          ((action.payload?.data?.asset_file_tenant_secondary || []).filter(
            (asset) => asset.post_specification === "serial_number"
          ))[0]?.file_url || "",

        residential_serial_image_url:
          ((action.payload?.data?.asset_file_tenant_secondary || []).filter(
            (asset) => asset.post_specification === "serial_number"
          ))[0]?.file_url || "",

        residential_image_url:
          ((action.payload?.data?.asset_file_tenant_secondary || []).filter(
            (asset) => asset.post_specification === "residential"
          ))[0]?.file_url || "",


      };
    },
    setCurrentSecondaryTenantIds: (state,action) => {
      state.current_secondary_tenant_id = (action.payload?.tenant_secondary || []).map(elem=> elem.tenant_secondary_id) || [];
    }
  },
});

export const {
  addSecondaryTenantForm,
  removeSecondaryTenantForm,
  addSecondaryTenantFormData,
  resetSecondaryTenantForms,
  addSecondaryTenantFormDataToEdit,
  setCurrentSecondaryTenantIds
} = slice.actions;
export default slice.reducer;
