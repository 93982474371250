import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "payments",
  initialState: {
    payment_method: [],

    account_holder: "",
    account_number: "",
    bank_name: "",
    iban: "",
    swift_code: "",

    cliq_alias: "",

    wallet_name: "",
    wallet_alias: "",

    specify_other_payment: "",

    renting_amount: "",
    is_deposit_amount: "false",
    deposit_amount: "",

    property_tax: "false",
    payment_duration: "at_beginning",
    frequent_payment: "month_1",
    
    currency: "JOD",

    is_guard_amount: "false",
    guard_amount: "",
    is_facility_amount: "false",
    facility_amount: "",
    
    

    is_other_payment: "false",

    payment_lateness_fine: "",
    
    other_payment: {},
    additionalsInfo: {},
  },
  reducers: {
    // actions => action handlers
    setInputValue: (state, action) => {
      state[action.payload?.key] = action.payload?.value;
    },
    resetInputsValue: (state, action) => {
      state.additionalsInfo = {};
      state.payment_method = [];
      state.account_holder = "";
      state.account_number = "";
      state.bank_name = "";
      state.iban = "";
      state.swift_code = "";
      state.cliq_alias = "";
      state.wallet_name = "";
      state.wallet_alias = "";
      state.specify_other_payment = "";
      state.is_deposit_amount = "false";
      state.deposit_amount = "";
      state.payment_duration = "at_beginning";
      state.property_tax = "false";
      state.renting_amount = "";
      state.currency = "JOD";
      state.is_guard_amount = "false";
      state.frequent_payment = "month_1";
      state.guard_amount = "";
      state.is_facility_amount = "false";
      state.facility_amount = "";
      state.is_other_payment = "false";
      state.payment_lateness_fine = "";

      state.other_payment = {};
    },
    setPrevData: (state, action) => {
      state.additionalsInfo = action.payload?.data?.additionalsInfo;
      state.payment_method = action.payload?.data?.payment_method;
      state.account_holder = action.payload?.data?.account_holder;
      state.account_number = action.payload?.data?.account_number;
      state.bank_name = action.payload?.data?.bank_name;
      state.iban = action.payload?.data?.iban;
      state.swift_code = action.payload?.data?.swift_code;
      state.cliq_alias = action.payload?.data?.cliq_alias;
      state.wallet_name = action.payload?.data?.wallet_name;
      state.wallet_alias = action.payload?.data?.wallet_alias;
      state.specify_other_payment = action.payload?.data?.specify_other_payment;
      state.is_deposit_amount = action.payload?.data?.is_deposit_amount;
      state.deposit_amount = action.payload?.data?.deposit_amount;
      state.payment_duration = action.payload?.data?.payment_duration;
      state.property_tax = action.payload?.data?.property_tax;
      state.renting_amount = action.payload?.data?.renting_amount;
      state.currency = action.payload?.data?.currency;
      state.is_guard_amount = action.payload?.data?.is_guard_amount;
      state.frequent_payment = action.payload?.data?.frequent_payment;
      state.guard_amount = action.payload?.data?.guard_amount;
      state.is_facility_amount = action.payload?.data?.is_facility_amount;
      state.facility_amount = action.payload?.data?.facility_amount;
      state.is_other_payment = action.payload?.data?.is_other_payment;
      state.payment_lateness_fine = action.payload?.data?.payment_lateness_fine;

      state.other_payment = action.payload?.data?.other_payment;
    },
    addOtherPayment: (state, action) => {
      state.other_payment[action.payload?.id] = {
        id: action.payload?.id,
        details: "",
        amount: "",
      };
    },
    removeOtherPayment: (state, action) => {
      delete state.other_payment[action.payload?.id];
    },
    addOtherPaymentData: (state, action) => {
      state.other_payment[action.payload?.id][action.payload?.key] =
        action.payload?.value;
    },
    removeAllOtherPayments: (state, action) => {
      state.other_payment = {};
    },
    addOtherPaymentDataToEdit: (state, action) => {
      state.other_payment[action.payload?.id] = {
        id: action.payload?.id,
        details: action.payload?.data?.details || "",
        amount : action.payload?.data?.amount || "",
        other_payment_id: action.payload?.data?.other_payment_id || "",
      }
    }
  },
});

export const {
  setInputValue,
  resetInputsValue,
  setCompDataToEdit,
  resetFileInputs,
  addOtherPayment,
  removeOtherPayment,
  addOtherPaymentData,
  removeAllOtherPayments,
  setPrevData,
  addOtherPaymentDataToEdit
} = slice.actions;
export default slice.reducer;
