function handleError(err) {
  let msg = "Oops! Something Went Wrong";
  if (err.code === "ERR_NETWORK" && err.message === "Network Error") {
    msg =
      "Oops! Our server is currently down. Please try again later. Thanks for your patience!";
  } else if (
    err?.response?.message === "read ECONNRESET" ||
    err?.response?.data?.error?.message === "read ECONNRESET"
  ) {
    msg = "Network Error. Please check your internet connection and try again.";
  } else if (
    err?.response?.message === "read ETIMEDOUT" ||
    err?.response?.data?.error?.message === "read ETIMEDOUT"
  ) {
    msg = "Network Error. Please check your internet connection and try again.";
  } else if (err?.response?.data?.error?.message) {
    msg = err?.response?.data?.error?.message;
  } else if (err?.response?.data?.response?.message) {
    msg = err?.response?.data?.response?.message;
  } else if (err?.message) {
    msg = err.message;
  }

  return msg;
}

export { handleError };
