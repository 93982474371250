const MenuItemsBeforeAuth = [
  {
    href: "/home",
    name: "home",
  },
  {
    href: "/auth/register",
    name: "register",
  },
  {
    href: "/auth/login",
    name: "login",
  },
];

const MenuItemsAfterAuth = [
  {
    href: "/profile/contract",
    name: "dashboard",
  },
  {
    href: "/profile/user",
    name: "profile-account",
  },
  {
    href: "auth/login?mode=logout",
    name: "logout",
  },
];
export { MenuItemsBeforeAuth, MenuItemsAfterAuth };
