import React, { useEffect } from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LangRouter() {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const curPath = window.location.pathname;

  useEffect(() => {
    if (lang && i18n.resolvedLanguage !== lang) {
      if (i18n.options.fallbackLng.includes(lang)) {
        i18n.changeLanguage(lang);
      } else {
        navigate(
          "/" + i18n.resolvedLanguage + curPath.replace(/^\/\w{2}\b/, ""),
          { replace: true }
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);
  return <Outlet />;
}

export default LangRouter;
