import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./language-switcher.scss";

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const curPath = window.location.pathname;
  const lang = curPath.substring(1, 3);

  function handleChangeLanguage() {
    if (i18n.resolvedLanguage === "en") {
      i18n.changeLanguage("ar");
      navigate(`/ar/${curPath.slice(4)}`, { replace: true });
    } else {
      navigate(`/en/${curPath.slice(4)}`, { replace: true });
      i18n.changeLanguage("en");
    }
    window.location.reload(false);
  }

  return (
    <div className="lang-switch-wrapper">
      <div className="lang-switch-btn">
        <input
          onClick={() => {
            handleChangeLanguage();
          }}
          id="language-toggle"
          className="check-toggle check-toggle-round-flat"
          type="checkbox"
        />
        <label htmlFor="language-toggle"></label>
        <span className={`${lang === "en" ? "on" : "off"}`}>EN</span>
        <span className={`${lang === "ar" ? "on" : "off"}`}>AR</span>
      </div>
    </div>
  );
}

export default LanguageSwitcher;
