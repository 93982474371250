import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "property-info",
  initialState: {
    property_type: "1",

    residential_profile_id: "",
    renting_purpose: "",

    family_document_image: "",
    neighbors_approval_image: "",
    careers_license_image: "",

    commercial_profile_id: "",
    commercial_office_id: "",

    is_electricity_transfer: "false",
    is_water_transfer: "false",
    is_gas_transfer: "false",
    electricity_meter_reading: "",
    water_meter_reading: "",
    gas_meter_reading: "",
    is_parking: "false",
    is_garden: "false",
    parking_count: "1",
    is_built_in_kitchen: "true",
    is_built_in_condition: "false",
    condition_count: "1",
  },
  reducers: {
    // actions => action handlers
    setInputValue: (state, action) => {
      state[action.payload?.key] = action.payload?.value;
    },
    resetFileInputs: (state, action) => {
      state.commercial_registration_image = "";
    },
    resetInputsValue: (state, action) => {
      state.landlord_account_id = "";
      state.tenant_account_id = "";
      state.property_type = "1";
      state.residential_profile_id = "";
      state.renting_purpose = "";
      state.family_document_image = "";
      state.neighbors_approval_image = "";
      state.careers_license_image = "";
      state.commercial_profile_id = "";
      state.commercial_office_id = "";
      state.is_electricity_transfer = "false";
      state.is_gas_transfer = "false";
      state.electricity_meter_reading = "";
      state.water_meter_reading = "";
      state.gas_meter_reading = "";
      state.is_water_transfer = "false";
      state.is_parking = "false";
      state.is_garden = "false";
      state.parking_count = "1";
      state.is_built_in_kitchen = "true";
      state.is_built_in_condition = "false";
      state.condition_count = "1";
    },
    setPrevData: (state, action) => {
      state.landlord_account_id = action.payload?.data?.landlord_account_id;
      state.tenant_account_id = action.payload?.data?.tenant_account_id;
      state.property_type = String(action.payload?.data?.property_type);
      state.residential_profile_id =
        action.payload?.data?.residential_profile_id;
      state.renting_purpose = action.payload?.data?.renting_purpose;
      state.family_document_image = state.family_document_image || "";
      state.neighbors_approval_image = state.neighbors_approval_image || "";
      state.careers_license_image = state.careers_license_image || "";
      state.commercial_profile_id = action.payload?.data?.commercial_profile_id;
      state.commercial_office_id = action.payload?.data?.commercial_office_id;
      state.is_electricity_transfer =
        action.payload?.data?.is_electricity_transfer;
      state.is_water_transfer = action.payload?.data?.is_water_transfer;
      state.is_gas_transfer = action.payload?.data?.is_gas_transfer;
      state.electricity_meter_reading =
        action.payload?.data?.electricity_meter_reading;
      state.water_meter_reading = action.payload?.data?.water_meter_reading;
      state.gas_meter_reading = action.payload?.data?.gas_meter_reading;
      state.is_parking = action.payload?.data?.is_parking;
      state.is_garden = action.payload?.data?.is_garden;
      state.parking_count = action.payload?.data?.parking_count;
      state.is_built_in_kitchen = action.payload?.data?.is_built_in_kitchen;
      state.is_built_in_condition = action.payload?.data?.is_built_in_condition;
      state.condition_count = action.payload?.data?.condition_count;
    },
  },
});

export const { setInputValue, resetInputsValue, setPrevData, resetFileInputs } =
  slice.actions;
export default slice.reducer;
