import publicAxios from "../publicHttpService";
import privateAxios from "../privateHttpService";

// Define the API EndPoint
const registerEndPoint = "/users/signup";
const loginEndPoint = "/users/signin";
const refreshTokenEndPoint = "/users/refreshmentToken";
const forgetPasswordEndPoint = "/users/forgetPassword";
const verifyEmailEndPoint = "/users/verifyEmail";
const resetPasswordEndPoint = "/users/resetPassword";
const recoverEmailEndPoint = "/users/recoverEmail";
const verifyContractEndPoint = "/contract/isValidContract";

// Register Function for users
const register = async (userInfo) => {
  try {
    const result = await publicAxios.post(registerEndPoint, userInfo);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

// Login function for Users Account
const login = async (credentials) => {
  try {
    const result = await publicAxios.post(loginEndPoint, credentials);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

const refreshToken = async () => {
  try {
    const result = await privateAxios.post(refreshTokenEndPoint);
    return result?.data;
  } catch (err) {
    throw err?.response?.data?.error?.message;
  }
};

const forgetPassword = async (email) => {
  try {
    const result = await publicAxios.post(forgetPasswordEndPoint, email);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

const verifyEmail = async (obj) => {
  try {
    const result = await publicAxios.post(verifyEmailEndPoint, obj);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

const resetPassword = async (obj) => {
  try {
    const result = await publicAxios.post(resetPasswordEndPoint, obj);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

const recoverEmail = async (obj) => {
  try {
    const result = await publicAxios.post(recoverEmailEndPoint, obj);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

const isValidContract = async ({ reference_number }) => {
  const param = `?reference_number=${reference_number}`;
  try {
    const result = await publicAxios.get(`${verifyContractEndPoint}${param}`);
    return result?.data;
  } catch (err) {
    throw err;
  }
};

export {
  register,
  login,
  refreshToken,
  forgetPassword,
  verifyEmail,
  resetPassword,
  recoverEmail,
  isValidContract,
};
