
export default function IconButton({
  children,
  className,
  ariaLabel,
  onClick,
  isStyleReset = true,
}) {
  return (
    <button
      className={`${className} ${isStyleReset ? 'btn-reset' : ''}`}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
