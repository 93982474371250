import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "heirs",
  initialState: {
    heirsFormData: {
      inheritor_first_name: "",
      inheritor_second_name: "",
      inheritor_third_name: "",
      inheritor_family_name: "",
      inheritor_id_number: "",
      total_shares_of_inheritance: "",
      is_reassigned_inheritor: "false",
      reassigned_inheritor_image: "",
      inheritance_proof_image: "",
      is_edit: "false",

      reassigned_inheritor_url: "",
      residential_image_url: "",
    },
    heirsSubFormsData: {},
  },
  reducers: {
    // actions => action handlers
    setHeirsFormData: (state, action) => {
      state.heirsFormData.is_edit = "true";
      state.heirsFormData[action.payload?.key] = action.payload?.value;
    },
    addHeirsSubFormsData: (state, action) => {
      state.heirsSubFormsData[action.payload?.id] = {
        id: action.payload?.id,
        landlord_account_id: "",
        first_name: "",
        second_name: "",
        third_name: "",
        family_name: "",
        email: "",
        phone_number: "",
        address: "",
        nationality: "Jordan",
        is_residential: "false",
        id_number: "",
        id_image: "",
        passport_number: "",
        passport_image: "",
        non_jordanian_serial_number: "",
        non_jordanian_serial_image: "",
        residential_number: "",
        residential_image: "",
        residential_serial_number: "",
        residential_serial_image: "",
        share_value: "",
        inheritance_id: "",
        is_phone_number: " ",

        is_edit: "false",

        id_image_url: "",
        passport_image_url: "",
        non_jordanian_serial_image_url: "",
        residential_serial_image_url: "",
        residential_image_url: "",
      };
    },
    removeHeirsSubFormsData: (state, action) => {
      delete state.heirsSubFormsData[action.payload?.id];
    },
    addHeirsNestedSubFormsData: (state, action) => {
      state.heirsSubFormsData[action.payload?.id]["is_edit"] = "true";

      state.heirsSubFormsData[action.payload?.id][action.payload?.key] =
        action.payload?.value;
    },
    resetHeirsSubFormsData: (state) => {
      state.heirsFormData.reassigned_inheritor_image = "";
      state.heirsFormData.inheritance_proof_image = "";
      state.heirsSubFormsData = {};
    },
    resetAllData: (state, action) => {
      state.heirsFormData = {
        inheritor_first_name: "",
        inheritor_second_name: "",
        inheritor_third_name: "",
        inheritor_family_name: "",
        inheritor_id_number: "",
        total_shares_of_inheritance: "",
        is_reassigned_inheritor: "false",
        reassigned_inheritor_image: "",
        inheritance_proof_image: "",
        is_edit: "false",
        reassigned_inheritor_url: "",
        residential_image_url: "",
      };
      state.heirsSubFormsData = {};
    },
    setHeirsDataToEdit: (state, action) => {
      state.heirsFormData = {
        is_edit: "false",
        inheritor_first_name: action.payload?.inheritor_first_name || "",
        inheritor_second_name: action.payload?.inheritor_father_name || "",
        inheritor_third_name: action.payload?.inheritor_grand_father_name || "",
        inheritor_family_name: action.payload?.inheritor_family_name || "",
        inheritor_id_number: action.payload?.inheritor_goverment_number || "",
        total_shares_of_inheritance:
          String(action.payload?.inheritor_total_share) || "",
        is_reassigned_inheritor:
          action.payload?.is_reassigned_inheritor === 0 ? "false" : "true",
        current_is_reassigned_inheritor:
          action.payload?.is_reassigned_inheritor === 0 ? "false" : "true",
        reassigned_inheritor_image: "",
        inheritance_proof_image: "",
        current_sub_inheritor_id:
          (action.payload?.landlord_inheritance || []).map(
            (elem) => elem.inheritance_id
          ) || [],

        reassigned_inheritor_url:
          (action.payload?.asset_file.filter(
            (asset) => asset.post_specification === "inheritance_reassign"
          ))[0]?.file_url || "",

        inheritance_proof_image_url:
          (action.payload?.asset_file.filter(
            (asset) => asset.post_specification === "inheritance"
          ))[0]?.file_url || "",
      };
    },
    addHeirsSubFormsDatatoEdit: (state, action) => {
      state.heirsSubFormsData[action.payload?.id] = {
        id: action.payload?.id,
        landlord_account_id: action.payload?.data?.landlord_account_id || "",
        current_nationality_type: action.payload?.data?.nationality_type || "",
        inheritance_id: action.payload?.data?.inheritance_id || "",

        first_name: action.payload?.data?.first_name || "",
        second_name: action.payload?.data?.father_name || "",
        third_name: action.payload?.data?.grand_father_name || "",
        family_name: action.payload?.data?.family_name || "",
        email: action.payload?.data?.email || "",
        phone_number:
          action.payload?.data?.country_code +
            action.payload?.data?.phone_number || "",
        address: action.payload?.data?.address || "",
        nationality: action.payload?.data?.nationality || "Jordan",
        is_residential:
          action.payload?.data?.nationality_type === "jo_nationality" ||
          action.payload?.data?.nationality_type === "none_jo_nationality"
            ? "false"
            : "true",
        is_corporate: action.payload?.data?.is_corporate || 0,
        is_inheritance:
          action.payload?.data?.is_inheritance === 1 ? "true" : "false",
        non_jordanian_serial_number: action.payload?.data?.serial_number || "",
        residential_serial_number: action.payload?.data?.serial_number || "",
        id_number:
          action.payload?.data?.nationality_type === "jo_nationality"
            ? action.payload?.data?.government_number
            : "",
        passport_number:
          action.payload?.data?.nationality_type === "none_jo_nationality"
            ? action.payload?.data?.government_number
            : "",
        residential_number:
          action.payload?.data?.nationality_type === "jo_residential"
            ? action.payload?.data?.government_number
            : "",
        non_jordanian_serial_image: "",
        id_image: "",
        passport_image: "",
        residential_image: "",
        residential_serial_image: "",
        share_value: action.payload?.data?.share_value || "",
        is_phone_number: " ",

        // handle images URLs
        id_image_url:
          ((action.payload?.data?.asset_file_landlord_inheritance || []).filter(
            (asset) => asset.post_specification === "nationality_id"
          ))[0]?.file_url || "",

        passport_image_url:
          ((action.payload?.data?.asset_file_landlord_inheritance || []).filter(
            (asset) => asset.post_specification === "passport"
          ))[0]?.file_url || "",

        non_jordanian_serial_image_url:
          ((action.payload?.data?.asset_file_landlord_inheritance || []).filter(
            (asset) => asset.post_specification === "serial_number"
          ))[0]?.file_url || "",

        residential_serial_image_url:
          ((action.payload?.data?.asset_file_landlord_inheritance || []).filter(
            (asset) => asset.post_specification === "serial_number"
          ))[0]?.file_url || "",

        residential_image_url:
          ((action.payload?.data?.asset_file_landlord_inheritance || []).filter(
            (asset) => asset.post_specification === "residential"
          ))[0]?.file_url || "",
      };
    },
  },
});

export const {
  setHeirsFormData,
  addHeirsSubFormsData,
  removeHeirsSubFormsData,
  addHeirsNestedSubFormsData,
  resetHeirsSubFormsData,
  resetAllData,
  setHeirsDataToEdit,
  addHeirsSubFormsDatatoEdit,
} = slice.actions;
export default slice.reducer;
