import React from "react";

import LazyShow from "../LazyShow";
import { LanguageSwitcher } from "..";

import "./footer.scss";

function Footer() {
  return (
    <LazyShow>
      <div id="footer" className="container px-4 py-6 mx-auto border-top">
        <LanguageSwitcher />
        <div className="flex items-center justify-center mt-3">
          <p className="mb-0 text-xs leading-none text-gray-900 lg:text-sm dark:text-gray-50">
            &copy; {new Date().getFullYear()} all rights reserved by{" "}
            <a href="https://aqdee.com" rel="nofollow">
              aqdee
            </a>
          </p>
        </div>
      </div>
    </LazyShow>
  );
}

export default Footer;
