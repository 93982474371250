import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "leasing-info",
  initialState: {
    landlord_account_id: "",
    tenant_account_id: "",
    property_type: "1",
    residential_profile_id: "",
    renting_purpose: "",
    family_document_image: "",
    neighbors_approval_image: "",
    careers_license_image: "",
    commercial_profile_id: "",
    commercial_office_id: "",

    renting_date: "",
    end_date: "",
    signing_date: "",
    is_leasing_before_date: "false",
    leasing_before_date: "",
    is_sub_leasing: "false",
    renewing_type: "approve_by_both",

    landlord_end_contract_duration: "1",
    tenant_end_contract_duration: "1",

    allowed_smoking: "true",
    pet_allowed: "true",

    is_water: "",
    is_electricity: "",
    is_condition: "",
    is_solar: "",

    addtional_terms: "",

    is_electricity_transfer: "false",
    is_water_transfer: "false",
    is_gas_transfer: "false",
    electricity_meter_reading: "",
    water_meter_reading: "",
    gas_meter_reading: "",
  },
  reducers: {
    // actions => action handlers
    setInputValue: (state, action) => {
      state[action.payload?.key] = action.payload?.value;
    },
    resetFileInputs: (state, action) => {
      state.commercial_registration_image = "";
    },
    resetInputsValue: (state, action) => {
      state.landlord_account_id = "";
      state.tenant_account_id = "";
      state.property_type = "1";
      state.residential_profile_id = "";
      state.renting_purpose = "";
      state.family_document_image = "";
      state.neighbors_approval_image = "";
      state.careers_license_image = "";
      state.commercial_profile_id = "";
      state.commercial_office_id = "";
      state.renting_date = "";
      state.end_date = "";
      state.signing_date = "";
      state.is_leasing_before_date = "false";
      state.leasing_before_date = "";
      state.is_sub_leasing = "false";
      state.is_electricity_transfer = "false";
      state.is_gas_transfer = "false";
      state.electricity_meter_reading = "";
      state.water_meter_reading = "";
      state.gas_meter_reading = "";
      state.is_water_transfer = "false";
      state.addtional_terms = "";
      state.renewing_type = "approve_by_both";
      state.landlord_end_contract_duration = "1";
      state.tenant_end_contract_duration = "1";
      state.allowed_smoking = "true";
      state.pet_allowed = "true";
      state.is_water = "";
      state.is_electricity = "";
      state.is_condition = "";
      state.is_solar = "";
    },
    setPrevData: (state, action) => {
      state.landlord_account_id = action.payload?.data?.landlord_account_id;
      state.tenant_account_id = action.payload?.data?.tenant_account_id;
      state.property_type = action.payload?.data?.property_type || "1";
      state.residential_profile_id =
        action.payload?.data?.residential_profile_id;
      state.renting_purpose = action.payload?.data?.renting_purpose;
      state.family_document_image = action.payload?.data?.family_document_image;
      state.neighbors_approval_image =
        action.payload?.data?.neighbors_approval_image;
      state.careers_license_image = action.payload?.data?.careers_license_image;
      state.commercial_profile_id = action.payload?.data?.commercial_profile_id;
      state.commercial_office_id = action.payload?.data?.commercial_office_id;

      state.renting_date = action.payload?.data?.renting_date.substring(0, 10);
      state.end_date = action.payload?.data?.end_date.substring(0, 10);
      state.signing_date = action.payload?.data?.signing_date.substring(0, 10);

      state.is_leasing_before_date =
        action.payload?.data?.is_leasing_before_date || "false";
      state.leasing_before_date =
        action.payload?.data?.leasing_before_date.substring(0, 10);
      state.is_sub_leasing = action.payload?.data?.is_sub_leasing || "false";
      state.is_electricity_transfer =
        action.payload?.data?.is_electricity_transfer;
      state.is_water_transfer = action.payload?.data?.is_water_transfer;
      state.addtional_terms = action.payload?.data?.addtional_terms;
      state.renewing_type =
        action.payload?.data?.renewing_type || "approve_by_both";
      state.landlord_end_contract_duration =
        action.payload?.data?.landlord_end_contract_duration || "1";
      state.tenant_end_contract_duration =
        action.payload?.data?.tenant_end_contract_duration || "1";

      state.is_gas_transfer = action.payload?.data?.is_gas_transfer;
      state.electricity_meter_reading =
        action.payload?.data?.electricity_meter_reading;
      state.water_meter_reading = action.payload?.data?.water_meter_reading;
      state.gas_meter_reading = action.payload?.data?.gas_meter_reading;
      state.allowed_smoking = action.payload?.data?.allowed_smoking || "true";
      state.pet_allowed = action.payload?.data?.pet_allowed || "true";
      state.is_water = action.payload?.data?.is_water;
      state.is_electricity = action.payload?.data?.is_electricity;
      state.is_condition = action.payload?.data?.is_condition;
      state.is_solar = action.payload?.data?.is_solar;
    },
  },
});

export const { setInputValue, resetInputsValue, setPrevData, resetFileInputs } =
  slice.actions;
export default slice.reducer;
