import { useState } from "react";
import { RiMenu3Fill, RiCloseLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import IconButton from "../icon-button";
import { MenuItemsBeforeAuth, MenuItemsAfterAuth } from "./MenuItems";
import { userLogout } from "../../utils/global";
import { removeLoginState } from "../../redux/userSession";

import "./navbar.scss";

export default function Navbar({ isLogin }) {
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const translateLabel = "headerMenu";
  const navLinkStyle = ({ isActive }) => {
    return {
      borderBottom: isActive ? "white 3px solid" : "none",
      textDecoration: "none",
    };
  };

  const handleShowHide = () => {
    showMenu ? setShowMenu(false) : setShowMenu(true);
  };

  const handleLogout = () => {
    userLogout(dispatch, removeLoginState(), navigate);
  };

  const handleMenuItems = () => {
    const menuItem = isLogin ? MenuItemsAfterAuth : MenuItemsBeforeAuth;

    return menuItem.map((menuItem, i) => {
      const url = window.location.pathname;
      const pathname = url.substring(3, url.length);
      const style = pathname === menuItem.href ? "active" : "";

      return (
        <li key={i} className={`${style}`}>
          <NavLink
            onClick={() => {
              handleShowHide();

              if (menuItem.name === "logout") {
                handleLogout();
              }
            }}
            to={menuItem.href}
            style={navLinkStyle}
            className={`header-item`}
          >
            {t(`${translateLabel}.${menuItem.name}`)}
          </NavLink>
        </li>
      );
    });
  };

  return (
    <div className="nav-bar-wrapper">
      <IconButton
        className="nav-bar__toggle-btn"
        ariaLabel={showMenu ? "Close Menu" : "Open Menu"}
        onClick={handleShowHide}
      >
        {!showMenu ? (
          <RiMenu3Fill className="nav-bar__toggle-btn__icon" />
        ) : (
          <RiCloseLine className="nav-bar__toggle-btn__icon" />
        )}
      </IconButton>

      <nav
        className={`nav-bar ${showMenu ? "nav-bar__show" : "nav-bar__hide"}`}
      >
        <ul className="nav-bar__list-items list-inline">{handleMenuItems()}</ul>
      </nav>
    </div>
  );
}
