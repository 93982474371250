import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setLoginState } from "../../redux/userSession";
import { getLocalStorage } from "../../utils/global";
import { Navbar } from "..";

import logo from "../../assets/images/logo/transparent/logo.png";

import "./header.scss";

function Header() {
  const dispatch = useDispatch();

  const { isLogin } = useSelector((state) => state.userSession);
  const aqdeeStorage = getLocalStorage();

  useEffect(() => {
    if (aqdeeStorage.user_info["token"]) {
      dispatch(
        setLoginState({
          value: aqdeeStorage.user_info?.role || "user",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  return (
    <header className="main-header">
      <a href="/home">
        <img src={logo} alt="Logo" className="logo" />
      </a>

      <Navbar isLogin={isLogin} />
    </header>
  );
}

export default Header;
